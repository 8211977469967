import React, { Component } from 'react';
import { Pagination as Spinner } from '../../firefly/component';
import { H2 } from '../../firefly/component';

export default class CryptoWalletBalances extends Component {
  
  fetch = () => {
    const {
      siteId
    } = this.props;

    this.props.fetch({
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {
    const {
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <H2>Криптобаланс</H2>
        <table style={{marginTop: 10 + 'px', padding: 10 + 'px'}}>
          <thead>
            <tr>
              <th style={{padding: 10 + 'px', fontWeight: 'bold'}}>Монета</th>
              <th style={{textAlign: 'right', padding: 10 + 'px', fontWeight: 'bold'}}>Баланс</th>
              <th style={{textAlign: 'right', padding: 10 + 'px', fontWeight: 'bold'}}>Адрес</th>
            </tr>
          </thead>
          <tbody>
          {objects.map(element => (
              <tr key={element.id}>
                <td style={{padding: 10 + 'px'}}>{element.codename}</td>
                <td style={{textAlign: 'right', padding: 10 + 'px'}}>{element.amount}</td>
                <td style={{textAlign: 'right', padding: 10 + 'px'}}>
                  <a target='_blank't rel="noreferrer" href={`https://bscscan.com/token/${element.address}`}>{element.address}</a>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }
}
