import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.NEWS_LIST,
    request: { url: `/news/list`, params }
  };
}

export function view(id) {
  return {
    type: types.NEWS_VIEW,
    request: { url: `/news/view/${id}` }
  };
}

export function create(data) {
  return {
    type: types.NEWS_CREATE,
    request: { url: '/news/create', method: 'post', data }
  };
}

export function update(data) {
  const id = data instanceof FormData ? data.get('id') : data.id;

  return {
    type: types.NEWS_UPDATE,
    request: { url: `/news/update/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.NEWS_REMOVE,
    request: { url: `/news/remove/${id}`, method: 'delete' }
  };
}


export function newnotif(data) {
  return {
    type: types.NEWS_NEWNOTIF,
    request: { url: '/news/new-notification', method: 'post', data }
  };
}