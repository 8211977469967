import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1 } from '../../../firefly/component';
import LoginForm from '../../../component/LoginForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class Login extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  render() {
    const {
      user
    } = this.props;

    if (null !== user) {
      return <Redirect to='/' />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Авторизация' }
    ];

    return (
      <Page title='Авторизция' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Авторизация</H1>
          <LoginForm />
        </Transition>
      </Page>
    );
  }
}
