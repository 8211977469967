import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import MonetaSelect from '../MonetaSelect';
import { isGranted } from '../../utils';

export default class DraftscanEditForm extends Form {

  componentDidMount() {
    const { form } = this.props;
    form.is_fulldraft = !!form.is_fulldraft;
    delete form.user;
    delete form.partner;
    console.log(form);

    this.setState({ form });
  }

  prepare(form) {
    const formData = new FormData();

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
    });

    return formData;
  }

  render() {
    const {
      user,
      errors,
      loading,
      id
    } = this.props;

    const {      
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>

        <Part type="checkbox"
            required={false}
            id="is_fulldraft"
            name="is_fulldraft"
            disabled={loading}
            label='Весь чек'
            value={form.is_fulldraft || ''}
            errors={errors}
            onChange={this.change('is_fulldraft')} />

          {!form.is_fulldraft && (
            <Part
              type="text"
              id="name"
              name="name"
              disabled={loading}
              label='Товар'
              value={form.name || ''}
              errors={errors}
              onChange={this.change('name')} />
          )}

          <Part
              component={MonetaSelect}
              id="moneta_id"
              name="moneta_id"
              disabled={loading}
              label='Монета'
              value={form.moneta_id || ''}
              errors={errors}
              onChange={this.change('moneta_id', 'reactselect')} />       

          <Part
            type="text"
            id="inn"
            name="inn"
            disabled={loading}
            label='ИНН'
            value={form.inn || ''}
            errors={errors}
            onChange={this.change('inn')} />

          <Part
            type="text"
            min="0.00"
            required={true}
            id="bonus"
            name="bonus"
            disabled={loading}
            label='Бонусы, %'
            value={form.bonus || ''}
            errors={errors}
            onChange={this.change('bonus')} />

          {isGranted(user, 'admin') && id === undefined && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')} />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Продолжить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
