import qs from 'qs';
import { withRouter } from 'react-router-dom';
import PartnerList from './PartnerList';
import { bindConnect } from '../../utils';

export default bindConnect((state, props) => ({
  filter: {
    page: 1,
    page_size: 10,
    ...qs.parse(props.location.search.slice(1))
  }
}))(withRouter(PartnerList));
