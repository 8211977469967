import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../component/PrivateRoute';

import Header from '../component/Header';
import Menu from '../component/Menu';
import Homepage from '../page/Homepage';

import Login from '../page/User/Login';
import Restore from '../page/User/Restore';
import RestoreConfirm from '../page/User/RestoreConfirm';
import Registration from '../page/User/Registration';
import RegistrationConfirm from '../page/User/RegistrationConfirm';
import ChangePassword from '../page/User/ChangePassword';

import Notifications from '../component/Notification/Notifications';
import NoMatch from '../page/NoMatch';

import CityList from '../page/CityList';
import CityCreate from '../page/CityCreate';
import CityUpdate from '../page/CityUpdate';

import CountryCreate from '../page/CountryCreate';
import CountryUpdate from '../page/CountryUpdate';

import ProductList from '../page/ProductList';
import ProductCreate from '../page/ProductCreate';
import ProductView from '../page/ProductView';
import ProductUpdate from '../page/ProductUpdate';
import ProductKassa from '../page/ProductKassa';

import SingleCodeList from '../page/SingleCodeList';
import SingleCodeCreate from '../page/SingleCodeCreate';
import SingleCodeUpdate from '../page/SingleCodeUpdate';

import PartnerList from '../page/PartnerList';
import PartnerView from '../page/PartnerView';

import PartnerCreate from '../page/PartnerCreate';
import PartnerUpdate from '../page/PartnerUpdate';

import ContestList from '../page/ContestList';
import ContestCreate from '../page/ContestCreate';
import ContestUpdate from '../page/ContestUpdate';
import ContestView from '../page/ContestView';

import Settings from '../page/Settings';

import OrderList from '../page/OrderList';

import UserList from '../page/UserList';
import UserView from '../page/UserView';
import UserCreate from '../page/UserCreate';

import CodeList from '../page/CodeList';
import CodeGenerate from '../page/CodeGenerate';
import CodeExport from '../page/CodeExport';

import LicensesList from '../page/LicensesList';
import LicenseBye from '../page/LicenseBye';
import LicenseUse from '../page/LicenseUse';
import LicenseActivate from '../page/LicenseActivate';
import StatReferals from '../page/StatReferals';

import MonetsList from '../page/MonetsList';
import MonetaAdd from '../page/MonetaAdd';
import MonetaImgs from '../page/MonetaImgs';
import EditMoneta from '../page/EditMoneta';

import BalanceAdd from '../page/BalanceAdd';
import PayDone from '../page/PayDone';

import NewsList from '../page/NewsList';
import NewsCreate from '../page/NewsCreate';
import NewsUpdate from '../page/NewsUpdate';
import NewNotif from '../page/NewNotif';

import PromocodeList from '../page/PromocodeList';
import PromocodeGenerate from '../page/PromocodeGenerate';
import PromocodeQr from '../page/PromocodeQr';

import StatPartner from '../page/StatPartner';

import DraftScanList from '../page/DraftScanList';
import DraftScanAdd from '../page/DraftScanAdd';
import DraftScanUpdate from '../page/DraftScanUpdate';
import DraftScanStat from '../page/DraftScanStat';

export default class Main extends Component {

  constructor(props) {
    super(props);
    const siteId = localStorage.getItem('siteId');
    this.state = {
      siteId: siteId != null ? siteId : 0,
      isShowMenu: true
    };
  }

  setSiteId = (siteId) => {
    this.setState({siteId: siteId});
    localStorage.setItem('siteId', siteId);
  }

  setHideMenu = () => {
    this.setState({isShowMenu: !this.state.isShowMenu});
  }

  render() {

    const {
      siteId
    } = this.state;

    return (
      <div className="wrapper">
        <div className="wrapper__menu">
          <Menu isShowMenu={this.state.isShowMenu} siteId={siteId} />
        </div>
        <div className="wrapper__container">
          <div className="wrapper__header">
            <Header setSiteId={this.setSiteId} siteId={siteId} setHideMenu={this.setHideMenu} />
          </div>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/restore/confirm' component={RestoreConfirm} />
            <Route exact path='/restore' component={Restore} />
            <Route exact path='/registration/confirm' component={RegistrationConfirm} />
            <Route exact path='/registration' component={Registration} />

            <PrivateRoute exact path='/' component={Homepage} siteId={siteId} />

            <PrivateRoute exact path='/city' component={CityList} />
            <PrivateRoute exact path='/city/create' component={CityCreate} />
            <PrivateRoute exact path='/city/update/:id' component={CityUpdate} />

            <PrivateRoute exact path='/country' component={CityList} />
            <PrivateRoute exact path='/country/create' component={CountryCreate} />
            <PrivateRoute exact path='/country/update/:id' component={CountryUpdate} />

            <PrivateRoute exact path='/single_code' component={SingleCodeList} siteId={siteId} />
            <PrivateRoute exact path='/single_code/create' component={SingleCodeCreate} siteId={siteId} />
            <PrivateRoute exact path='/single_code/update/:id' component={SingleCodeUpdate} />

            <PrivateRoute exact path='/code' component={CodeList} siteId={siteId} />
            <PrivateRoute exact path='/code/generate' component={CodeGenerate} siteId={siteId} />
            <PrivateRoute exact path='/code/export' component={CodeExport} />

            <PrivateRoute exact path='/change_password' component={ChangePassword} />

            <PrivateRoute exact path='/contest' component={ContestList} siteId={siteId} />
            <PrivateRoute exact path='/contest/create' component={ContestCreate} siteId={siteId} />
            <PrivateRoute exact path='/contest/:id' component={ContestView} />
            <PrivateRoute exact path='/contest/update/:id' component={ContestUpdate} />

            <PrivateRoute exact path='/settings' component={Settings} siteId={siteId} />

            <PrivateRoute exact path='/product' component={ProductList} siteId={siteId} />
            <PrivateRoute exact path='/product/update/:id' component={ProductUpdate} siteId={siteId} />
            <PrivateRoute exact path='/product/create' component={ProductCreate} siteId={siteId} />
            <PrivateRoute exact path='/product/kassa' component={ProductKassa} siteId={siteId} />
            <PrivateRoute exact path='/product/:id' component={ProductView} />

            <PrivateRoute exact path='/partner' component={PartnerList} siteId={siteId} />
            <PrivateRoute exact path='/partner/update/:id' component={PartnerUpdate} siteId={siteId} />
            <PrivateRoute exact path='/partner/create' component={PartnerCreate} siteId={siteId} />
            <PrivateRoute exact path='/partner/:id' component={PartnerView} siteId={siteId} />

            <PrivateRoute exact path='/user/:id' component={UserView} siteId={siteId} />
            <PrivateRoute exact path='/user' component={UserList} siteId={siteId} />
            <PrivateRoute exact path='/users/create' component={UserCreate} siteId={siteId} />

            <PrivateRoute exact path='/order' component={OrderList} />
            
            <PrivateRoute exact path='/licenses' component={LicensesList} />
            <PrivateRoute exact path='/licenses/bye' component={LicenseBye} />
            <PrivateRoute exact path='/licenses/activate' component={LicenseActivate} />            
            <PrivateRoute exact path='/licenses/use/:id' component={LicenseUse} />

            <PrivateRoute exact path='/statreferals' component={StatReferals} />

            <PrivateRoute exact path='/monets' component={MonetsList} siteId={siteId} />
            <PrivateRoute exact path='/monets/add' component={MonetaAdd} siteId={siteId} />
            <PrivateRoute exact path='/monets/imgs/:id' component={MonetaImgs} />
            <PrivateRoute exact path='/monets/update/:id' component={EditMoneta} siteId={siteId} />

            <PrivateRoute exact path='/balance/add' component={BalanceAdd} />
            <PrivateRoute exact path='/balance/paydone/:id' component={PayDone} />
            <PrivateRoute exact path='/balance/editmoneta' component={EditMoneta} />

            <PrivateRoute exact path='/news' component={NewsList} siteId={siteId} />
            <PrivateRoute exact path='/news/create' component={NewsCreate} siteId={siteId} />
            <PrivateRoute exact path='/news/update/:id' component={NewsUpdate} />
            <PrivateRoute exact path='/news/newnotif' component={NewNotif} siteId={siteId} />            

            <PrivateRoute exact path='/promocode' component={PromocodeList} siteId={siteId} />
            <PrivateRoute exact path='/promocode/generate' component={PromocodeGenerate} siteId={siteId} />
            <PrivateRoute exact path='/promocode/qr/:id' component={PromocodeQr} />

            <PrivateRoute exact path='/draftscan' component={DraftScanList} />
            <PrivateRoute exact path='/draftscan/add' component={DraftScanAdd} />
            <PrivateRoute exact path='/draftscan/update/:id' component={DraftScanUpdate} />
            <PrivateRoute exact path='/draftscan/stat' component={DraftScanStat} />

            <PrivateRoute exact path='/stat' component={StatPartner} siteId={siteId} />

            <Route component={NoMatch} />
          </Switch>
        </div>
        <Notifications />
      </div>
    );
  }
}
