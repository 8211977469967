import * as types from '../actionTypes';

export function save(data) {
  return {
    type: types.SETTINGS_SAVE,
    request: { url: '/settings', method: 'post', data }
  };
}

export function list(params) {
  return {
    type: types.SETTINGS_LIST,
    request: { url: '/settings', method: 'get', params }
  };
}
