import { bindConnect, isValidationError } from '../../utils';
import { doLogin } from '../../redux/actions/auth';
import LoginForm from './LoginForm';

export default bindConnect(state => ({
  loading: Boolean(state.user_login.pending),
  errors: isValidationError(state.user_login) ? state.user_login.error.response.data.errors : []
}), {
  callback: doLogin
})(LoginForm);
