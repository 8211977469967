import { bindConnect } from '../../utils';
import PartnerUpdate from './PartnerUpdate';
import { doFetchPartner } from '../../redux/actions/partner';

export default bindConnect(state => ({
  loading: Boolean(state.partner_view.pending) || !state.partner_view.data,
  partner: state.partner_view.data
}), {
  fetch: doFetchPartner
})(PartnerUpdate);
