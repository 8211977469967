import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ProductCreateForm from '../../component/ProductCreateForm';

export default class ProductCreate extends Component {
  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      id: response.data.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;

    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to={`/product/${id}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/product', text: 'Подарки' },
      { icon: 'document', text: 'Добавление подарка' }
    ];

    return (
      <Page title='Добавление подарка' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление подарка</H1>
          <ProductCreateForm
            onSuccess={this.onSuccess}
            siteId={siteId}
         />
        </Transition>
      </Page>
    );
  }
}
