import { success } from 'redux-saga-requests';
import { loadUser } from '../../utils';
import * as types from '../actionTypes';

const initialState = loadUser();

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case success(types.USER_LOGIN):
    case success(types.USER_SET):
    case success(types.USER_REGISTRATION_CONFIRM):
    case success(types.USER_RESTORE_CONFIRM):
      return {
        ...state,
        jwt: action.data.jwt,
        user: action.data.user
      };

    case types.USER_LOGOUT:
      return {
        ...state,
        jwt: null,
        user: null
      };

    default:
      return state;
  }
};

export default currentUser;