import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import ProductKassaForm from './ProductKassaForm';
import * as productActions from '../../redux/actions/product';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    site_id: props.siteId,
    partner_id: state.current_user.user.id
  } : {
    ...props.form,
    site_id: props.siteId
  },
  errors: getValidationErrors(state.product_kassacode),
  loading: Boolean(state.product_kassacode.pending)
}), {
  callback: productActions.kassacode
})(ProductKassaForm);
