import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.STAT_LIST,
    request: { url: '/stat/list', params }
  };
}

export function statreferals(params) {
  return {
    type: types.STAT_STATREFERALS,
    request: { url: '/stat/statreferals', params }
  };
}
