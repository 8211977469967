import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Link } from 'react-router-dom';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';
import { filterValues } from '../../utils';

export default class ContestTable extends Component {
  static propTypes = {
    filter: PropTypes.object,
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    meta: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    filter: {},
    loading: true,
    objects: null,
    meta: null,
    className: ''
  };

  onRemove = id => e => {
    e.preventDefault();

    const {
      remove,
      notification
    } = this.props;

    if (window.confirm('Удалить?')) {
      remove(id).then(() => {
        notification({ title: 'Удалено' });
        this.fetch();
      });
    }
  };

  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  fetch = (page = 1, page_size = 10) => {
    const {
      filter,
      fetch,
      siteId
    } = this.props;

    const parameters = filterValues({
      ...filter,
      page_size,
      page,
      site_id: siteId
    });

    fetch(parameters);
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {
    const {
      className,
      loading,
      meta,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        key: t => (
          <Link className="link" to={`/contest/${t.id}`}>
            {t.name}
          </Link>
        ),
        title: 'Название'
      },
      {
        key: 'price',
        title: 'Стоимость участия'
      },
      {
        key: x => x.start_at ? x.start_at : (
          <Icons.Minus />
        ),
        title: 'Дата начала'
      },
      {
        key: x => x.end_at ? x.end_at : (
          <Icons.Minus />
        ),
        title: 'Дата окончания'
      },
      {
        key: x => x.winner_id ? (
          <Link to={`/user/${x.winner_id}`}>
            {x.email}
          </Link>
        ) : (
          <Icons.Minus />
        ),
        title: 'Победитель'
      },
      {
        key: x => x.city_id ? x.city.name : <Icons.Minus />,
        title: 'Город'
      },
      {
        key: t => (
          <Link to={`/partner/${t.partner_id}`}>
            {t.partner.name}
          </Link>
        ),
        title: 'Партнер'
      },
      {
        key: x => (
          <Fragment>
            <Link className="link" to={`/contest/update/${x.id}`}>
              <Icons.Edit2 className="icon" />
            </Link>
            <button onClick={this.onRemove(x.id)} className="link">
              <Icons.Trash2 className="icon" />
            </button>
          </Fragment>
        ),
        title: '',
        tdStyle: {
          align: 'right',
          width: 100,
          textAlign: 'center'
        }
      }
    ];

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />
        {!loading && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total} />}
      </div>
    );
  }
}
