import { bindConnect, getValidationErrors } from '../../utils';
import CountryForm from '../CountryForm';
import * as countryActions from '../../redux/actions/country';

export default bindConnect((state, props) => ({
  errors: getValidationErrors(state.country_create),
  loading: Boolean(state.country_create.pending)
}), {
  callback: countryActions.create
})(CountryForm);
