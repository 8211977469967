import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Link } from 'react-router-dom';
import Table from '../Table';
import { Spinner } from '../../firefly/component';

export default class CityTable extends Component {
  static propTypes = {
    filter: PropTypes.object,
    renderColumns: PropTypes.array,
    fetch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    objects: PropTypes.array,
    className: PropTypes.string
  };

  static defaultProps = {
    objects: null,
    className: '',
    renderColumns: [],
    filter: {}
  };
  fetch = () => {
    const {
      fetch,
      filter
    } = this.props;

    fetch(filter);
  };
  onRemove = id => e => {
    e.preventDefault();

    const {
      remove,
      notification
    } = this.props;

    if (window.confirm('Удалить?')) {
      remove(id).then(() => {
        notification({ title: 'Удалено' });
        this.fetch();
      });
    }
  };

  UNSAFE_componentWillMount() {
    this.fetch();
  }

  render() {
    const {
      renderColumns,
      className,
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        column: 'name',
        key: 'name',
        title: 'Название'
      },
      {
        column: 'country_id',
        key: x => x.country_id ? x.country.name : <Icons.Minus />,
        title: 'Страна'
      },
      {
        key: x => (
          <Fragment>
            <Link className="link" to={`/city/update/${x.id}`}>
              <Icons.Edit2 className="icon" />
            </Link>
            <button onClick={this.onRemove(x.id)} className="link">
              <Icons.Trash2 className="icon" />
            </button>
          </Fragment>
        ),
        title: '',
        tdStyle: {
          align: 'right',
          width: 100,
          textAlign: 'center'
        }
      }
    ];

    return (
      <div>
        <Table
          className={className}
          renderColumns={renderColumns}
          columns={columns}
          data={objects} />
      </div>
    );
  }
}
