import { withRouter } from 'react-router-dom';
import { bindConnect, getValidationErrors } from '../../utils';
import { didRegistrationConfirm, didRegistrationResend } from '../../redux/actions/auth';
import RegistrationConfirmForm from './RegistrationConfirmForm';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  errors: getValidationErrors(state.registration_confirm),
  loading: Boolean(state.registration_confirm.pending) || Boolean(state.registration_resend.pending)
}), {
  notification: notificationActions.add,
  callback: didRegistrationConfirm,
  resend: didRegistrationResend
})(withRouter(RegistrationConfirmForm));
