import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1, Spinner } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ContestUpdateForm from '../../component/ContestUpdateForm';

export default class ContestUpdate extends Component {
  static propTypes = {
    contest: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired
  };

  static defaultProps = {
    loading: true,
    contest: null
  };

  state = {
    success: false,
    id: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  onSuccess = ({ form }) => {
    this.setState({
      id: form.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;

    if (success) {
      return <Redirect to={`/contest/${id}`} />;
    }

    const {
      loading,
      contest
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/contest', text: 'Конкурсы' },
      { icon: 'document', text: 'Редактирование конкурса' }
    ];

    return (
      <Page title='Редактирование конкурса' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <H1>Редактирование конкурса</H1>
            <ContestUpdateForm
              reset={false}
              onSuccess={this.onSuccess}
              form={contest} />
          </Transition>
        )}
      </Page>
    );
  }
}
