import { bindConnect } from '../../utils';
import LicenseTable from './LicenseTable';
import * as licenseActions from '../../redux/actions/license';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.license_list.pending) || !state.license_list.data,
  ...(state.license_list.data || { meta: {}, objects: [] })
}), {
  //remove: licenseActions.remove,
  fetch: licenseActions.list,
  notification: notificationActions.add
})(LicenseTable);