import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import { Link } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import DraftScanTable from '../../component/DraftScanTable';
import { isGranted } from "../../utils";

export default class DraftScanList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Чеки' }
    ];

    const {
      user
    } = this.props;

    const filter = isGranted(user, 'admin') ? {} : {
      partner_id: user.id
    };

    return (
      <Page title='Чеки' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Чеки</H1>
          <div className="toolbar">
            <Link to='/draftscan/add' className="b-button">
              Создать
            </Link>
            &nbsp;
            <Link to='/draftscan/stat' className="b-button">
              Статистика
            </Link>
          </div>
          <DraftScanTable filter={filter} />
        </Transition>
      </Page>
    );
  }
}
