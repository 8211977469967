import React, { Component } from 'react';
import { H1, SmartDetailTable } from '../../firefly/component';
import { Link } from 'react-router-dom';
import * as Icons from 'react-feather';

export default class PartnerLanding extends Component {

  render() {
    const {
      partner
    } = this.props;

    const mapping = [
      {
        title: 'Логотип',
        value: x => x && x.logo ? (
          <img src={x.logo_url} alt={x.name} className='partner__logo' />
        ) : (
          <Icons.Minus className='icon' />
        )
      },
      {
        title: 'Руководитель',
        value: x => x && x.user && (
          x.user.email
        )
      },
      {
        title: 'Описание',
        value: 'description'
      },
      {
        title: 'Город',
        value: x => x && x.city && (
          x.city.name
        )
      },
      {
        title: 'Адрес',
        value: x => x && x.address && (
          x.address
        )
      },      
      {
        title: 'Телефон',
        value: x => x && x.phone && (
          x.phone
        )
      }
    ];

    return (
      <div>
        <div className="detail-view__panel">
          <H1>{partner !== null ? partner.name : ''}</H1>
        </div>
        <SmartDetailTable
          object={partner}
          mapping={mapping} />
        {partner !== null && partner.is_account_activation !== "1" ? (<p><strong>Демо режим</strong></p>) : (<></>)}
        {partner !== null && partner.is_account_activation !== "1" ? 
          (<div className="toolbar"><Link to='/licenses/activate' className="b-button">
            Активировать профиль
           </Link></div>) : 
          (<></>)
        }
      </div>
    );
  }
}
