import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SpinnerOverlay } from '../../firefly/component';
import ImageList from '../ImageList';

export default class ProductImageList extends Component {
  static propTypes = {
    product_id: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired
  };
  onRemove = id => {
    const {
      remove,
      fetch,
      product_id
    } = this.props;

    if (window.confirm('Удалить изображение?')) {
      remove(id).then(() => {
        fetch(product_id);
      });
    }
  };

  componentDidMount() {
    const {
      fetch,
      product_id
    } = this.props;

    fetch(product_id);
  }

  render() {
    const {
      loading,
      ...rest
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <ImageList onRemove={this.onRemove} {...rest} />
      </SpinnerOverlay>
    );
  }
}
