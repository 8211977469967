import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class CitySelect extends Form {

  componentDidMount() {
    this.props.fetch();
  }

  render() {

    const { 
      onChange, 
      objects,
      value,
      loading
    } = this.props;

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={objects}
        value={value}
        placeholder="Выберите город"
      /> : <select className="b-input b-input--select"></select>)
    );
  }
}
