import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.LICENSE_LIST,
    request: { url: '/license/list', params }
  };
}

export function doLicenseBye(data) {
  return {
    type: types.LICENSE_BYE,
    request: { url: '/license/bye', method: 'post', data }
  };
}

export function doLicenseActivate(data) {
  return {
    type: types.LICENSE_ACTIVATE,
    request: { url: '/license/activate', method: 'post', data }
  };
}

export function doLicenseUse(data) {
  return {
    type: types.LICENSE_USE,
    request: { url: '/license/use', method: 'post', data }
  };
}