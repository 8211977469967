import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class UserSelect extends Form {

  componentDidMount() {
    const {
      siteId,
      fetch
    } = this.props;

    fetch({site_id: siteId});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId,
      fetch
    } = this.props;

    if (prevProps.siteId !== siteId) {
      fetch({site_id: siteId})
    }
  }

  render() {
    const {
      objects,
      //name,
      value,
      loading,
      onChange
    } = this.props;

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={objects}
        value={value}
        placeholder="Выберите пользователя"
      /> : <select className="b-input b-input--select"></select>)
    );

    /*return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        {!loading && objects.map((user, i) => (
          <option key={i} value={user.id}>{user.email}</option>
        ))}
      </select>
    );*/
  }
}
