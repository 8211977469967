import { bindConnect } from '../../utils';
import NewsCreate from './NewsCreate';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  siteId: props.siteId
}), {
  notification: notificationActions.add
})(NewsCreate);
