import { bindConnect } from '../../utils';
import DraftScanTable from './DraftScanTable';
import * as draftscanActions from '../../redux/actions/draftscan';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.draftscan_list.pending) || !state.draftscan_list.data,
  ...(state.draftscan_list.data || { meta: {}, objects: [] })
}), {
  remove: draftscanActions.remove,
  fetch: draftscanActions.list,
  notification: notificationActions.add
})(DraftScanTable);