import { bindConnect, getValidationErrors } from '../../utils';
import CityForm from '../CityForm';
import * as cityActions from '../../redux/actions/city';

export default bindConnect((state, props) => ({
  errors: getValidationErrors(state.city_create),
  loading: Boolean(state.city_create.pending)
}), {
  callback: cityActions.create
})(CityForm);
