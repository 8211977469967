import React from 'react';
import { Form } from '../../firefly/component';

export default class ContestCategSelect extends Form {

  render() {
    const {
      name,
      value,
      onChange
    } = this.props;

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={0}>Все</option>
        <option value={1}>Заведения</option>
        <option value={2}>Места</option>
        <option value={3}>Разное</option>        
      </select>
    );
  }
}
