import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import CountryCreateForm from '../../component/CountryCreateForm';

export default class CountryCreate extends Component {
  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      success: true
    });
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return <Redirect to='/city' />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/city', text: 'Города' },
      { icon: 'document', text: 'Добавление страны' }
    ];

    return (
      <Page title='Добавление страны' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление страны</H1>
          <CountryCreateForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
