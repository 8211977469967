import { bindConnect, isValidationError } from '../../utils';
import RestoreForm from './RestoreForm';
import { didRestore } from '../../redux/actions/auth';

export default bindConnect(state => ({
  errors: isValidationError(state.restore) ? state.restore.error.response.data.errors : [],
  loading: Boolean(state.restore.pending)
}), {
  callback: didRestore
})(RestoreForm);
