import { bindConnect } from '../../utils';
import EditMoneta from './EditMoneta';
import * as monetsActions from '../../redux/actions/monets';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
    user: state.current_user.user,
    loading: Boolean(state.monets_view.pending) || !state.monets_view.data,
    ...(state.monets_view.data || { meta: {}, objects: [] }),
    moneta: state.monets_view.data
}), {
    fetch: monetsActions.view,
    notification: notificationActions.add
})(EditMoneta);