import React, { Component, Fragment } from 'react';
import { Pagination as Spinner } from '../../firefly/component';

export default class UserReferals extends Component {
  
  fetch = () => {
    const {
      siteId,
      id 
    } = this.props;

    this.props.fetch({
      user_id: id,
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId,
      id
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId || prevProps.id !== id) {
      this.fetch();
    }
  }

  subReferals(referals) {    
    return referals.map(element => (
      <Fragment key={element.id}>
        <tr>
          <td style={{paddingLeft: 20 + 'px', paddingRight: 15 + 'px'}}>{element.phone}</td>
          <td>{element.email}</td>
        </tr>
        {element.referals !== null ? this.subReferals(element.referals) : ''}
      </Fragment>
    ))
  }

  render() {
    const {
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }
    return (
      <div>
        
        <h3 style={{paddingLeft: 10 + 'px', paddingTop: 15 + 'px', fontWeight: 'bold'}}>Рефералы</h3>
        {!objects.length && (<div style={{paddingLeft: 10 + 'px'}}>Нет</div>)}
        <table style={{marginTop: 10 + 'px', padding: 10 + 'px'}}>
          <tbody>
          {objects.map(element => (
            <Fragment key={element.id}>
              <tr>
                <td style={{paddingLeft: 10 + 'px', paddingRight: 15 + 'px'}}>{element.phone}</td>
                <td>{element.email}</td>                
              </tr>
              {element.referals !== null ? this.subReferals(element.referals) : ''}
            </Fragment>
          ))}
          </tbody>
        </table>
      </div>
    );
  }
}
