import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import UserDetail from './UserDetail';
import * as userActions from '../../redux/actions/user';

export default bindConnect((state, props) => ({
  userl: state.current_user.user,
  form: !isGranted(state.current_user.user, 'admin') && isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    partner_id: state.current_user.user.id
  } : {
    ...props.form
  },
  errors: getValidationErrors(state.user_update),
  loading: Boolean(state.user_update.pending),
}), {
  callback: userActions.update
})(UserDetail);
