export const PARTNER_OPTIONS = 'PARTNER_OPTIONS';
export const PARTNER_LIST = 'PARTNER_LIST';
export const PARTNER_VIEW = 'PARTNER_VIEW';
export const PARTNER_CREATE = 'PARTNER_CREATE';
export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PARTNER_REMOVE = 'PARTNER_REMOVE';
export const PARTNER_BALANCE = 'PARTNER_BALANCE';
export const PARTNER_BALANCEADD = 'PARTNER_BALANCEADD';
export const PARTNER_BALANCEPAYDONE = 'PARTNER_BALANCEPAYDONE';

export const CITY_LIST = 'CITY_LIST';
export const CITY_OPTIONS = 'CITY_OPTIONS';
export const CITY_CREATE = 'CITY_CREATE';
export const CITY_UPDATE = 'CITY_UPDATE';
export const CITY_REMOVE = 'CITY_REMOVE';
export const CITY_VIEW = 'CITY_VIEW';

export const COUNTRY_LIST = 'COUNTRY_LIST';
export const COUNTRY_OPTIONS = 'COUNTRY_OPTIONS';
export const COUNTRY_CREATE = 'COUNTRY_CREATE';
export const COUNTRY_UPDATE = 'COUNTRY_UPDATE';
export const COUNTRY_REMOVE = 'COUNTRY_REMOVE';
export const COUNTRY_VIEW = 'COUNTRY_VIEW';

export const POLL_LIST = 'POLL_LIST';
export const POLL_VIEW = 'POLL_VIEW';
export const POLL_CREATE = 'POLL_CREATE';
export const POLL_UPDATE = 'POLL_UPDATE';
export const POLL_REMOVE = 'POLL_REMOVE';

export const OPTION_LIST = 'OPTION_LIST';
export const OPTION_VIEW = 'OPTION_VIEW';
export const OPTION_CREATE = 'OPTION_CREATE';
export const OPTION_UPDATE = 'OPTION_UPDATE';
export const OPTION_REMOVE = 'OPTION_REMOVE';

export const PRODUCT_IMAGE_REMOVE = 'PRODUCT_IMAGE_REMOVE';
export const PRODUCT_IMAGE_CREATE = 'PRODUCT_IMAGE_CREATE';
export const PRODUCT_IMAGE_LIST = 'PRODUCT_IMAGE_LIST';

export const SETTINGS_LIST = 'SETTINGS_LIST';
export const SETTINGS_SAVE = 'SETTINGS_SAVE';

export const CONTEST_LIST = 'CONTEST_LIST';
export const CONTEST_CREATE = 'CONTEST_CREATE';
export const CONTEST_UPDATE = 'CONTEST_UPDATE';
export const CONTEST_REMOVE = 'CONTEST_REMOVE';
export const CONTEST_VIEW = 'CONTEST_VIEW';

export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_VIEW = 'PRODUCT_VIEW';
export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_REMOVE = 'PRODUCT_REMOVE';
export const PRODUCT_OPTIONS = 'PRODUCT_OPTIONS';
export const PRODUCT_KASSACODE = 'PRODUCT_KASSACODE';
export const PRODUCT_KASSAVYDAT = 'PRODUCT_KASSAVYDAT';

export const CODE_LIST = 'CODE_LIST';
export const CODE_GENERATE = 'CODE_GENERATE';
export const CODE_DOWNLOAD = 'CODE_DOWNLOAD';

export const SINGLE_CODE_LIST = 'SINGLE_CODE_LIST';
export const SINGLE_CODE_CREATE = 'SINGLE_CODE_CREATE';
export const SINGLE_CODE_UPDATE = 'SINGLE_CODE_UPDATE';
export const SINGLE_CODE_REMOVE = 'SINGLE_CODE_REMOVE';
export const SINGLE_CODE_VIEW = 'SINGLE_CODE_VIEW';

export const USER_SET = 'USER_SET';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_RESTORE = 'USER_RESTORE';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_OPTIONS = 'USER_OPTIONS';
export const USER_LIST = 'USER_LIST';
export const USER_BALANCES = 'USER_BALANCES';
export const USER_REFERALS = 'USER_REFERALS';
export const USER_ACTIVITIES = 'USER_ACTIVITIES';
export const USER_VIEW = 'USER_VIEW';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REMOVE = 'USER_REMOVE';
export const USER_PARTNER = 'USER_PARTNER';
export const USER_ADMIN = 'USER_ADMIN';
export const USER_RESTORE_CHECK = 'USER_RESTORE_CHECK';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_RESTORE_CONFIRM = 'USER_RESTORE_CONFIRM';
export const USER_REGISTRATION_CONFIRM = 'USER_REGISTRATION_CONFIRM';
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_RESTORE_RESEND = 'USER_RESTORE_RESEND';
export const USER_REGISTRATION_RESEND = 'USER_REGISTRATION_RESEND';

export const ORDER_LIST = 'ORDER_LIST';

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const USER_LOCALSTORAGE_KEY = '__user';

export const LICENSE_LIST = 'LICENSE_LIST';
export const LICENSE_BYE = 'LICENSE_BYE';
export const LICENSE_ACTIVATE = 'LICENSE_ACTIVATE';
export const LICENSE_USE = 'LICENSE_USE';

export const MONETS_LIST = 'MONETS_LIST';
export const MONETS_ADD = 'MONETS_ADD';
export const MONETS_VIEW = 'MONETS_VIEW';
export const MONETS_REMOVE = 'MONETS_REMOVE';
export const MONETS_UPDATE = 'MONETS_UPDATE';
export const MONETS_OPTIONS = 'MONETS_OPTIONS';
export const MONETS_CRUPTOBALANCES = 'MONETS_CRUPTOBALANCES';

export const MONETA_IMAGE_CREATE = 'MONETA_IMAGE_CREATE';
export const MONETA_IMAGE_REMOVE = 'MONETA_IMAGE_REMOVE';
export const MONETA_IMAGE_LIST = 'MONETA_IMAGE_LIST';

export const NEWS_LIST = 'NEWS_LIST';
export const NEWS_VIEW = 'NEWS_VIEW';
export const NEWS_CREATE = 'NEWS_CREATE';
export const NEWS_UPDATE = 'NEWS_UPDATE';
export const NEWS_REMOVE = 'NEWS_REMOVE';
export const NEWS_NEWNOTIF = 'NEWS_NEWNOTIF';

export const PROMOCODE_LIST = 'PROMOCODE_LIST';
export const PROMOCODE_GENERATE = 'PROMOCODE_GENERATE';
export const PROMOCODE_DOWNLOAD = 'PROMOCODE_DOWNLOAD';
export const PROMOCODE_EXPORT = 'PROMOCODE_EXPORT';

export const STAT_LIST = 'STAT_LIST';
export const STAT_STATREFERALS = 'STAT_STATREFERALS';

export const DRAFTSCAN_LIST = 'DRAFTSCAN_LIST';
export const DRAFTSCAN_ADD = 'DRAFTSCAN_ADD';
export const DRAFTSCAN_VIEW = 'DRAFTSCAN_VIEW';
export const DRAFTSCAN_REMOVE = 'DRAFTSCAN_REMOVE';
export const DRAFTSCAN_UPDATE = 'DRAFTSCAN_UPDATE';
export const DRAFTSCAN_OPTIONS = 'DRAFTSCAN_OPTIONS';
export const DRAFTSCAN_STAT = 'DRAFTSCAN_STAT';
