import { bindConnect, getValidationErrors } from '../../utils';
import CodeGenerateForm from './CodeGenerateForm';
import { doCodeGenerate } from '../../redux/actions/code';

export default bindConnect(state => ({
  errors: getValidationErrors(state.code_generate),
  loading: Boolean(state.code_generate.pending)
}), {
  callback: doCodeGenerate
})(CodeGenerateForm);
