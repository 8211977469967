import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import { isGranted } from '../../utils';

export default class LicenseByeForm extends Form {
  render() {
    const {
      user,
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <div>Для активации будет списан 1 EMR</div>
          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')} />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Активировать
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
