import { bindConnect } from '../../utils';
import Settings from './Settings';
import * as notificationActions from '../../redux/actions/notifications';
import * as settingsActions from '../../redux/actions/settings';

export default bindConnect(state => ({
  loading: Boolean(state.settings_list.pending) || !state.settings_list.data,
  settings: state.settings_list.data
}), {
  fetch: settingsActions.list,
  notification: notificationActions.add
})(Settings);
