import React, { Component } from 'react';
import { Pagination as Spinner } from '../../firefly/component';

export default class UserBalance extends Component {
  
  fetch = () => {
    const {
      siteId,
      id 
    } = this.props;

    this.props.fetch({
      user_id: id,
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId,
      id
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId || prevProps.id !== id) {
      this.fetch();
    }
  }

  render() {
    const {
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }    

    return (
      <div>
        <h3 style={{paddingLeft: 10 + 'px', paddingTop: 15 + 'px', fontWeight: 'bold'}}>Cчёт</h3>
        <table style={{marginTop: 10 + 'px', padding: 10 + 'px'}}>
          <thead>
          <tr><th style={{padding: 10 + 'px'}}>Монета</th><th style={{textAlign: 'right', padding: 10 + 'px'}}>Баланс</th></tr>
          </thead>
          <tbody>
          {objects.map(element => (
              <tr key={element.id}>
                <td style={{paddingLeft: 10 + 'px', paddingRight: 15 + 'px'}}>{element.codename}</td>
                <td style={{textAlign: 'right'}}>{element.amount}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }
}
