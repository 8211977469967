import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import OrderTable from '../../component/OrderTable';
import PermissionContainer from '../../component/PermissionContainer';

export default class OrderList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Заказы' }
    ];

    return (
      <Page title='Заказы' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Заказы</H1>
            <OrderTable />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
