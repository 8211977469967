import { bindConnect, getValidationErrors } from '../../utils';
import SingleCodeForm from '../SingleCodeForm';
import * as singleCodeActions from '../../redux/actions/singleCode';

export default bindConnect(state => ({
  errors: getValidationErrors(state.single_code_update),
  loading: Boolean(state.single_code_update.pending)
}), {
  callback: singleCodeActions.update
})(SingleCodeForm);
