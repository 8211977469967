import { bindConnect } from '../../utils';
import PayDone from './PayDone';
import * as partnerActions from '../../redux/actions/partner';

export default bindConnect(state => ({
  loading: Boolean(state.partner_balancepaydone.pending) || !state.partner_balancepaydone.data,
  data: state.partner_balancepaydone.data
}), {
  fetch: partnerActions.balancePayDone,
})(PayDone);
