import * as types from '../actionTypes';

export function didUserSet(data) {
  return {
    type: types.USER_SET,
    data
  };
}

export function didChangePassword(data) {
  return {
    type: types.USER_CHANGE_PASSWORD,
    request: { url: '/user/change_password', method: 'post', data }
  };
}

export function options(data) {
  return {
    type: types.USER_OPTIONS,
    request: { url: `/user/options?site_id=${data.site_id ?? 0}`, data }
  };
}

export function list(params) {
  return {
    type: types.USER_LIST,
    request: { url: '/user/list', params }
  };
}

export function balances(params) {
  return {
    type: types.USER_BALANCES,
    request: { url: '/user/balances', params }
  };
}

export function referals(params) {
  return {
    type: types.USER_REFERALS,
    request: { url: '/user/referals', params }
  };
}

export function activities(params) {
  return {
    type: types.USER_ACTIVITIES,
    request: { url: '/user/activities', params }
  };
}
export function view(id) {
  return {
    type: types.USER_VIEW,
    request: { url: `/user/${id}` }
  };
}

export function create(data) {
  return {
    type: types.USER_CREATE,
    request: { url: '/user/create', method: 'post', data }
  };
}

export function update(data) {
  const { id } = data;
  return {
    type: types.USER_UPDATE,
    request: { url: `/user/update/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.USER_REMOVE,
    request: { url: `/user/${id}`, method: 'delete' }
  };
}

export function admin(id, data) {
  return {
    type: types.USER_ADMIN,
    request: { url: `/user/admin/${id}`, method: 'post', data }
  };
}

export function partner(id, data) {
  return {
    type: types.USER_PARTNER,
    request: { url: `/user/partner/${id}`, method: 'post', data }
  };
}
