import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';

export default class ProductDetail extends Component {
  static propTypes = {
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      product
    } = this.props;

    const val2 = () => {
      if (product.price1 > 0) {
        return <div>валюта2: {product.price1}</div>
      }
      return ''
    }
    const val3 = () => {
      if (product.price2 > 0) {
        return <div>валюта3: {product.price2}</div>
      }
      return ''
    }

    return (
      <div>
        <H1>{product.name}</H1>
        <div>
          <div>Стоимость: {product.price}</div>
          {val2()}
          {val3()}
        </div>
        <div>Количество: {product.quantity}</div>
        <div>Город: {product.city ? product.city.name : ''}</div>
        <div>Страна: {product.country ? product.country.name : ''}</div>
        <div className="divider" />
        <div>{product.description}</div>
      </div>
    );
  }
}
