import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import { Spinner } from '../../firefly/component';

export default class PromocodeQr extends Component {
  static propTypes = {
    image: PropTypes.object,
    loading: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    image: null,
    loading: true
  };

  componentDidMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      match,
      siteId
    } = this.props;

    if ((false === loading || prevProps.match === null || prevProps.siteId !== siteId) && null !== match) {
      if (match.params.id !== this.props.match.params.id) {
        fetch(match.params.id);
      }
    }
  }

  render() {
    const {
      loading,
      image,
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/promocode', text: 'Промокоды' },
      { icon: 'document', text: 'Просмотр QR кода' }
    ];

    return (
      <Page title='Просмотр QR кода' breadcrumbs={breadcrumbs}>
        {loading || !image ? <Spinner /> : (
          <Transition>
            <img src={'data:image/png;base64, ' + image.data} alt="qr" />
          </Transition>
        )}
      </Page>
    );
  }
}
