import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import { Redirect } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import NewsUpdateForm from '../../component/NewsUpdateForm';
import PermissionContainer from '../../component/PermissionContainer';
import { Spinner } from '../../firefly/component';

export default class NewsUpdate extends Component {
  static propTypes = {
    newsdata: PropTypes.object,
    loading: PropTypes.bool,
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    loading: true,
    newsdata: null
  };

  state = {
    success: false,
    id: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch,
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  onSuccess = () => {
    this.props.notification({ title: 'Выполнено' });

    this.setState({ success: true });
  };

  render() {
    const {
      loading,
      newsdata
    } = this.props;

    const {
      success
    } = this.state;

    if (success) {
      return <Redirect to={`/news`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/news', text: 'Новости' },
      { text: 'Изменение новости' }
    ];

    return (
      <Page breadcrumbs={breadcrumbs} title="Изменение новости" >
        <PermissionContainer>
          {loading ? <Spinner /> : <Transition>
            <H1>Изменение новости</H1>
            <NewsUpdateForm form={newsdata} onSuccess={this.onSuccess} />
          </Transition>}
        </PermissionContainer>
      </Page>
    );
  }
}
