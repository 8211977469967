import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import CodeGenerateForm from '../../component/CodeGenerateForm';
import PermissionContainer from '../../component/PermissionContainer';

export default class CodeGenerate extends Component {
  state = {
    success: false
  };

  onSuccess = () => {
    this.setState({
      success: true
    });
  };

  render() {
    const {
      success
    } = this.state;

    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to="/code" />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/code', text: 'Коды' },
      { text: 'Генерирование кодов' }
    ];

    return (
      <Page title='Генерирование кодов' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Генерирование кодов</H1>
            <CodeGenerateForm onSuccess={this.onSuccess} siteId={siteId} />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
