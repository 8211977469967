import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PartnerCreateForm from '../../component/PartnerCreateForm';
import PermissionContainer from '../../component/PermissionContainer';

export default class PartnerCreate extends Component {
  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      id: response.data.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;
    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to={`/partner/${id}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/partner', text: 'Партнеры' },
      { text: 'Добавление партнера' }
    ];

    return (
      <Page breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Добавление партнера</H1>
            <PartnerCreateForm
              onSuccess={this.onSuccess}
              siteId={siteId}
            />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
