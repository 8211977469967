import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PromocodeGenerateForm from '../../component/PromocodeGenerateForm';

export default class PromocodeGenerate extends Component {
  state = {
    success: false
  };

  onSuccess = () => {
    this.setState({
      success: true
    });
  };

  render() {
    const {
      success
    } = this.state;

    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to="/promocode" />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/promocode', text: 'Промокоды' },
      { text: 'Генерирование промокодов' }
    ];

    return (
      <Page title='Генерирование промокодов' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Генерирование промокодов</H1>
          <PromocodeGenerateForm onSuccess={this.onSuccess} siteId={siteId} />
        </Transition>
      </Page>
    );
  }
}
