import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.PARTNER_LIST,
    request: { url: '/partner/list', params }
  };
}

export function doFetchPartnerOptions(params) {
  return {
    type: types.PARTNER_OPTIONS,
    request: { url: '/partner/options', params }
  };
}

export function doFetchPartner(id) {
  return {
    type: types.PARTNER_VIEW,
    request: { url: `/partner/view/${id}` }
  };
}

export function doPartnerCreate(data) {
  return {
    type: types.PARTNER_CREATE,
    request: { url: '/partner/create', method: 'post', data }
  };
}

export function doPartnerUpdate(data) {
  const id = data instanceof FormData ? data.get('id') : data.id;

  return {
    type: types.PARTNER_UPDATE,
    request: { url: `/partner/update/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.PARTNER_REMOVE,
    request: { url: `/partner/remove/${id}`, method: 'delete' }
  };
}

export function balance(data) {
  const id = data instanceof FormData ? data.get('id') : data.id;
  return {
    type: types.PARTNER_BALANCE,
    request: { url: `/partner/balance/${id}` }
  };
}

export function balanceAdd(data) {
  return {
    type: types.PARTNER_BALANCEADD,
    request: { url: `/partner/balance-add`, method: 'post', data }
  };
}

export function balancePayDone(id) {
  return {
    type: types.PARTNER_BALANCEPAYDONE,
    request: { url: `/partner/balance-pay-done/${id}` }
  };
}
