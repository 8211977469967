import React from 'react';
import { Form } from '../../firefly/component';

export default class CountrySelect extends Form {
  UNSAFE_componentWillMount() {
    this.props.fetch();
  }

  render() {
    const {
      objects,
      name,
      value,
      onChange
    } = this.props;

    const nodes = objects.map((item, i) => (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    ));

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        {nodes}
      </select>
    );
  }
}
