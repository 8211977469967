import React from 'react';
import { Form } from '../../firefly/component';

export default class MonetaTypeSelect extends Form {
  render() {
    const {
      name,
      value,
      onChange
    } = this.props;

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        <option value="0">Only BackEnd Tokens (inGame only)</option>
        <option value="1">BSC (BEP-20) Tokens + BackEnd (onChain + inGame)</option>
      </select>
    );
  }
}
