import { bindConnect } from '../../utils';
import DraftScanUpdate from './DraftScanUpdate';
import * as draftscanActions from '../../redux/actions/draftscan';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
    user: state.current_user.user,
    loading: Boolean(state.draftscan_view.pending) || !state.draftscan_view.data,
    ...(state.draftscan_view.data || { meta: {}, objects: [] }),
    draftscan: state.draftscan_view.data
}), {
    fetch: draftscanActions.view,
    notification: notificationActions.add
})(DraftScanUpdate);