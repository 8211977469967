import { bindConnect, getValidationErrors } from '../../utils';
import { didChangePassword } from '../../redux/actions/user';
import ChangePasswordForm from './ChangePasswordForm';

export default bindConnect(state => ({
  errors: getValidationErrors(state.change_password),
  loading: Boolean(state.change_password.pending)
}), {
  callback: didChangePassword
})(ChangePasswordForm);
