import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Icons from 'react-feather';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';

export default class NewsTable extends Component {
  static propTypes = {
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    filter: PropTypes.object,
    remove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    meta: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    loading: true,
    objects: [],
    filter: {},
    meta: {},
    className: '',
    siteId: 0
  };
  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  fetch = (page = 1, page_size = 10) => {
    const {
      fetch,
      filter,
      siteId
    } = this.props;

    fetch({
      ...filter,
      page_size,
      page,
      site_id: siteId
    });
  };
  onRemove = id => e => {
    e.preventDefault();

    const {
      remove,
      notification
    } = this.props;

    if (window.confirm('Удалить?')) {
      remove(id).then(() => {
        notification({ title: 'Удалено' });
        this.fetch();
      });
    }
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {
    const {
      className,
      loading,
      meta,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        key: t => t.head,
        title: 'Заголовок'
      },
      {
        key: t => t.message.substr(0, 50)+"...",
        title: 'Сообщение'
      },
      {
        key: t => (
          <Link className="link" to={`/poll/${t.id}`}>
            Результаты опроса
          </Link>
        ),
        title: 'Результаты опроса'
      },
      {
        key: 'date_add',
        title: 'Дата'
      },
      {
        key: x => (
          <Fragment>
            <Link className="link" to={`/news/update/${x.id}`}>
              <Icons.Edit2 className="icon" />
            </Link>
            <button onClick={this.onRemove(x.id)} className="link">
              <Icons.Trash2 className="icon" />
            </button>
          </Fragment>
        ),
        title: '',
        tdStyle: {
          align: 'right',
          width: 100,
          textAlign: 'center'
        }
      }
    ];

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />
        {!loading && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total} />}
      </div>
    );
  }
}
