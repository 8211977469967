import { bindConnect } from '../../utils';
import UserBalance from './UserBalance';
import * as userActions from '../../redux/actions/user';

export default bindConnect((state,props) => ({
  user: state.current_user.user,
  loading: Boolean(state.user_balances.pending) || !state.user_balances.data,
  ...(state.user_balances.data || { meta: {}, objects: [] })
}), {
  fetch: userActions.balances,
})(UserBalance);
