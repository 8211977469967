import { bindConnect } from '../../utils';
import MonetaSelect from './MonetaSelect';
import { doFetchMonetaOptions } from '../../redux/actions/monets';

export default bindConnect((state, props) => ({
  monets: state.monets_options.data,
  loading: Boolean(state.monets_options.pending),
  partner_id: props.partner_id
}), {
  fetch: doFetchMonetaOptions
})(MonetaSelect);
