import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import BalanceAddForm from '../../component/BalanceAddForm';
import { Spinner } from '../../firefly/component';

export default class BalanceAdd extends Component {
  state = {
    success: false
  };

  onSuccess = ({ response }) => {
    this.setState({
      success: true
    });
    window.location.href = response.data.url;
  };

  fetch = (id = 0) => {
    const {
      fetch,
      filter
    } = this.props;

    fetch({
      ...filter,
      id
    });
  };  

  UNSAFE_componentWillMount() {
    this.fetch();
  }

  UNSAFE_componentWillReceiveProps({ loading, objects }) {
    if (false === loading && null === objects) {
      this.fetch();
    }
  }

  render() {
    const {
      success,
    } = this.state;

    const {
      //loading,
      objects
    } = this.props;

    if (success) {
      return <Spinner />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/licenses', text: 'Лицензии' },
      { text: 'Пополнить баланс' }
    ];

    return (
      <Page title='Пополнить баланс' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Пополнить баланс</H1>
          <h5>Баланс: {objects === undefined ? '...' : objects['balance']}</h5>
          <BalanceAddForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
