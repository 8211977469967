import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import UserTable from '../../component/UserTable';
import { Link } from 'react-router-dom';
import UserActivities from '../../component/UserActivities';
import CityFilter from '../../component/CityFilter';
import CountryFilter from '../../component/CountryFilter';
import GenderFilter from '../../component/GenderFilter';
import AgeFilter from '../../component/AgeFilter';
import UsersSort from '../../component/UsersSort';

export default class UserList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filtercity: '',
      filtercountry: '',
      filtergender: -1,
      filterage: 0,
      sort: 0
    };
  }

  onFilterCity(e, f) {
    this.setState({
      filtercity: e.value > 0 ? e.label : ''
    })
  }

  onFilterCountry(e, f) {
    this.setState({
      filtercountry: e.value > 0 ? e.label : ''
    })
  }
  onFilterGender(e, f) {
    //console.dir(e)
    //console.dir(f)
    this.setState({
      filtergender: e.value > 0 ? e.value : -1
    })
  }
  onFilterAge(e, f) {
    //console.dir(e)
    //console.dir(f)
    this.setState({
      filterage: e.value > 0 ? e.value : 0
    })
  }

  sortClick(e) {
    console.dir(e)
    console.dir(e.target.value)
    this.setState({
      sort: e.target.value
    })
  }

  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Пользователи' }
    ];

    const {
      filtercity,
      filtercountry,
      filtergender,
      filterage,
      sort
    } = this.state;

    const {
      siteId
    } = this.props;

    return (
      <Page title='Пользователи' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Пользователи</H1>
          <div className="toolbar">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div>
                <Link to={`/users/create`} className="b-button">
                  Добавить пользователя
                </Link>
              </div>
              <div style={{marginLef: '10px'}}>
                <UserActivities siteId={siteId} />
              </div>
            </div>
          </div>
          <div style={{display:'flex', flexDirection: 'row', marginBottom: '10px'}}>
          <div style={{flex: 0.25}}>
                Ранжирование: <UsersSort siteId={siteId}
                onChange={(e) => this.sortClick(e)}
              />
            </div>
            <div style={{flex: 0.25, marginLeft: '10px'}}>
              Фильтр страны: <CountryFilter siteId={siteId}
                onChange={(e, filter) => this.onFilterCountry(e, filter)}
              />
            </div>
            <div style={{flex: 0.25, marginLeft: '10px'}}>
              Фильтр города: <CityFilter siteId={siteId}
                onChange={(e, filter) => this.onFilterCity(e, filter)}
              />
            </div>
            <div style={{flex: 0.15, marginLeft: '10px'}}>
              Пол: <GenderFilter onChange={(e, filter) => this.onFilterGender(e, filter)} />
            </div>
            <div style={{flex: 0.15, marginLeft: '10px'}}>
              Возраст: <AgeFilter onChange={(e, filter) => this.onFilterAge(e, filter)} />
            </div>
          </div>
          <UserTable
            sort={sort}
            siteId={siteId}
            filtercity={filtercity}
            filtercountry={filtercountry}
            filtergender={filtergender}
            filterage={filterage}
          />
        </Transition>
      </Page>
    );
  }
}
