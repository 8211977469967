import { bindConnect } from '../../utils';
import CountryUpdate from './CountryUpdate';
import * as countryActions from '../../redux/actions/country';

export default bindConnect(state => ({
  loading: Boolean(state.country_view.pending) || !state.country_view.data,
  country: state.country_view.data
}), {
  fetch: countryActions.view
})(CountryUpdate);
