import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import { bindConnect, isAdmin } from '../../utils';
import { Popover } from '../../firefly/component';

class Menu extends Component {
  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired
  };
  static defaultProps = {
    user: null
  };
  market = undefined;
  manage = undefined;
  refHandlers = {
    market: market => this.market = market,
    manage: manage => this.manage = manage,
  };

  closePopover = () => {
    if (this.market) {
      this.market.close();
    }
    if (this.manage) {
      this.manage.close();
    }
  };

  render() {
    const {
      user,
      isShowMenu,
      siteId
    } = this.props;

    return (
      isShowMenu ? <div className="padding">
        <Logo siteId={siteId} />
        {user && (
          <div className="menu">            
            <Link className="menu__link" to="/product">
              Подарки
            </Link>
            <Link className="menu__link" to="/contest">
              Конкурсы
            </Link>
            <Link className="menu__link" to="/promocode">
              Промокоды
            </Link>
            <Link className="menu__link" to="/user">
              Пользователи
            </Link>
            <Link className="menu__link" to="/stat">
              Статистика
            </Link>
            {isAdmin(user) && (
              <Link className="menu__link" to="/monets">
                Монеты
              </Link>
            )}
            <Link className="menu__link" to="/draftscan">
              Чеки
            </Link>
            <Link onClick={this.closePopover} className="menu__item" to="/licenses">
              Рефералы
            </Link>
            {isAdmin(user) && (
              <Fragment>            
                <Link className="menu__link" to="/news">
                  Новости
                </Link>
              </Fragment>
            )}
            {!isAdmin(user) && (
              <Fragment>            
                <Link className="menu__link" to="/balance/add">
                  Пополнение баланса
                </Link>
              </Fragment>
            )}
            {isAdmin(user) && (
              <Fragment>
                <Popover ref={this.refHandlers.manage} modifiers="menu">
                  <button className='menu__link'>
                    Управление
                  </button>
                  <div>
                    <Link onClick={this.closePopover} className="menu__item" to="/partner">
                      Партнеры
                    </Link>
                    <Link onClick={this.closePopover} className="menu__item" to="/code">
                      Коды
                    </Link>
                    <Link onClick={this.closePopover} className="menu__item" to="/single_code">
                      Рекламные коды
                    </Link>
                    <Link onClick={this.closePopover} className="menu__item" to="/city">
                      Города
                    </Link>
                    <Link onClick={this.closePopover} className="menu__item" to="/settings">
                      Настройки
                    </Link>
                  </div>
                </Popover>
              </Fragment>
            )}
          </div>
        )}
      </div> : <div/>
    );
  }
}

export default bindConnect(state => ({
  user: state.current_user.user
}))(Menu);
