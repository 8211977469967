import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import ProductSelect from '../ProductSelect';
import { DateInput } from '../../firefly/component/DateTime';
import { isGranted } from '../../utils';
import MonetaSelect from '../MonetaSelect';

export default class PromocodeGenerateForm extends Form {

  parseDate(date) {
    console.log(date);
    let d = new Date(date);
    return d;
  }

  formatDate(date, locale) {
    let d = new Date(date);
    d = d.toISOString();
    return d.substr(8,2) + "." + d.substr(5,2) + "." + d.substr(0,4);
  }

  parseDateStr(date) {
    if (date) {
      let d = new Date(date);
      return d;
    }
  }

  prepare(form) {
    const {
      siteId
    } = this.props;

    form['site_id'] = siteId;
    return form;
  }

  render() {
    const {
      errors,
      loading,
      user,
      siteId
    } = this.props;

    const {
      form
    } = this.state;    

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="number"
            min="1"
            id="count"
            name="count"
            disabled={loading}
            label='Количество кодов'
            value={form.count || ''}
            errors={errors}
            onChange={this.change('count')} />

          {form.is_unlimit !== true ? 
            <Part
              type="number"
              min="0"
              id="counttouse"
              name="counttouse"
              disabled={loading}
              label='Число раз использования кода'
              value={form.counttouse || ''}
              errors={errors}
              onChange={this.change('counttouse')} /> : <></>
          }
          
          <Part 
            required={false}          
            type="checkbox"
            id="is_unlimit"
            name="is_unlimit"
            disabled={loading}
            label='Неограничено использование'
            value={form.is_unlimit || ''}
            errors={errors}
            onChange={this.change('is_unlimit')} />          

          <Part 
            required={false}          
            type="checkbox"
            id="is_limittouser"
            name="is_limittouser"
            disabled={loading}
            label='Ограничение использования кода одним пользователем'
            value={form.is_limittouser || ''}
            errors={errors}
            onChange={this.change('is_limittouser')} />

          {form.is_limittouser === true ? 
            <Part
              type="number"
              min="0"
              id="limittouser"
              name="limittouser"
              disabled={loading}
              label='Число использования кода одним пользователем'
              value={form.limittouser || ''}
              errors={errors}
              onChange={this.change('limittouser')} /> : <></>
          }

          <Part
            required={false}          
            type="number"
            min="0"
            id="amount"
            name="amount"
            disabled={loading}
            label='Количество получаемых монет'
            value={form.amount || ''}
            errors={errors}
            onChange={this.change('amount')} />

          <Part
              component={MonetaSelect}
              id="moneta_id"
              name="moneta_id"
              disabled={loading}
              label='Монета'
              value={form.moneta_id || ''}
              errors={errors}
              onChange={this.change('moneta_id')}
              siteId={siteId}
            />
          
          <Part
            required={false}
            type="number"
            min="0"
            id="products"
            name="products"
            disabled={loading}
            label='Количество получаемых подарков'
            value={form.products || ''}
            errors={errors}
            onChange={this.change('products')} />

          <Part
            required={false}
            component={ProductSelect}
            id="product_id"
            name="product_id"
            disabled={loading}
            label='Подарок'
            value={form.product_id || ''}
            errors={errors}
            onChange={this.change('product_id')}
            siteId={siteId}
          />            
            
          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')}
              siteId={siteId}
            />
          )}

          <Part
            required={false}
            type="textarea"
            id="comment"
            name="comment"
            disabled={loading}
            label='Комментарий'
            value={form.comment || ''}
            errors={errors}
            onChange={this.change('comment')} />

          <Part
            required={false}
            component={
              () => (
                <DateInput 
                  locale="ru-RU" 
                  value={this.parseDateStr(form.date_end)} 
                  parseDate={this.parseDate} 
                  formatDate={this.formatDate} 
                  onChange={(e) => {
                    const { form } = this.state;
                    form.date_end = this.parseDateStr(e);
                    this.setState({
                      form: {
                        ...form,
                      }
                    });
                    console.log(form);
                  }}
                />
            )}
            id="date_end"
            name="date_end"
            disabled={loading}
            label='Срок действия'
            value={form.date_end || ''}
            errors={errors}
            onChange={this.change('date_end')} />            

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
