import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ContestCreateForm from '../../component/ContestCreateForm';

export default class ContestCreate extends Component {
  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      id: response.data.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;

    const {
      siteId
    } = this.props;    

    if (success) {
      return <Redirect to={`/contest/${id}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/contest', text: 'Конкурсы' },
      { icon: 'document', text: 'Добавление конкурса' }
    ];

    return (
      <Page title='Добавление конкурса' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление конкурса</H1>
          <ContestCreateForm onSuccess={this.onSuccess} siteId={siteId} />
        </Transition>
      </Page>
    );
  }
}
