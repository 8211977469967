import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class AgeFilter extends Form {

  componentDidMount() {
    /*const {
      siteId,
      fetch
    } = this.props;

    fetch({
      site_id: siteId
    });*/
  }

  render() {

    const { 
      onChange, 
      //objects,
      value,
      loading
    } = this.props;

    const objects = [];
    for (let i = 0; i < 100; i++) {
      objects.push({value: i+1, label: i.toString()});
    }

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={objects}
        value={value}
        placeholder="Возраст"
      /> : <select className="b-input b-input--select"></select>)
    );
  }
}
