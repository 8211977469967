import { bindConnect } from '../../utils';
import ProductTable from './ProductTable';
import * as productActions from '../../redux/actions/product';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect((state, props) => ({
  loading: Boolean(state.product_list.pending) || !state.product_list.data,
  siteId: props.siteId,
  ...(state.product_list.data || { meta: {}, objects: [] })  
}), {
  fetch: productActions.list,
  remove: productActions.remove,
  notification: notificationActions.add
})(ProductTable);
