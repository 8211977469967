import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class CountrySelect extends Form {

  componentDidMount() {
    const {
      siteId,
      fetch
    } = this.props;

    fetch({
      site_id: siteId
    });
  }

  render() {

    const { 
      onChange, 
      objects,
      value,
      loading
    } = this.props;

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={objects}
        value={value}
        placeholder="Выберите страну"
      /> : <select className="b-input b-input--select"></select>)
    );
  }
}
