import { bindConnect } from '../../utils';
import CountrySelect from './CountrySelect';
import * as countryActions from '../../redux/actions/country';

export default bindConnect(state => ({
  objects: state.country_options.data || [],
  loading: Boolean(state.country_options.pending) || null === state.country_options.data
}), {
  fetch: countryActions.options
})(CountrySelect);
