import React, { Component } from 'react';
import { Pagination as Spinner } from '../../firefly/component';

export default class UserBalances extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      filter: ''
    };
  }

  fetch = () => {
    const {
      siteId,
      filtercity,
      filtercountry,
      filtergender,
      filterage    
    } = this.props;

    const {
      filter
    } = this.state;

    this.props.fetch({
      filter,
      filtercity,
      filtercountry,
      filtergender,
      filterage,
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  filterClick(e, filter) {
    e.preventDefault()
    this.setState({
      filter: filter
    })


    this.props.onFilter(e, filter)
    
    //this.forceUpdate(() => this.fetch())
  }

  render() {
    const {
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <table style={{marginTop: 10 + 'px', padding: 10 + 'px'}}>
          <thead>
          <tr><th style={{padding: 10 + 'px'}}>Итого</th><th style={{textAlign: 'right', padding: 10 + 'px'}}>Баланс</th></tr>
          </thead>
          <tbody>
          {objects.map(element => (
              <tr key={element.id}>
                { /* eslint-disable-next-line */ }
                <td style={{paddingLeft: 10 + 'px', paddingRight: 10 + 'px'}}><a href='#' onClick={(e) => this.filterClick(e, element.id)} >{element.codename}</a></td>
                <td style={{textAlign: 'right'}}>{element.amount}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }
}
