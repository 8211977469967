import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import CodeTable from '../../component/CodeTable';
import PermissionContainer from '../../component/PermissionContainer';

export default class CodeList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Коды' }
    ];

    const {
      siteId
    } = this.props;

    return (
      <Page title='Коды' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Коды</H1>
            <div className="toolbar">
              <Link to="/code/generate" className="b-button">
                Генерировать коды
              </Link>
              <Link to="/code/export" className="b-button">
                Экспорт кодов
              </Link>
            </div>
            <CodeTable siteId={siteId} />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
