import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { local } from 'easy-storage';
import { USER_LOCALSTORAGE_KEY } from './redux/actionTypes';

export function bindActions(actions) {
  return dispatch => bindActionCreators(actions, dispatch);
}

export function bindConnect(reducers, actions, options = {}) {
  if (typeof actions !== 'object') {
    return connect(reducers);
  }

  return connect(reducers, bindActions(actions), null, options);
}

export function persistUser(data) {
  local.set(USER_LOCALSTORAGE_KEY, data);
}

export function genericState(state, name) {
  return {
    loading: (Boolean(state[name].pending) || !state[name].data) && !state[name].error,
    error: state[name].error,
    ...(state[name].data)
  };
}

export function isAdmin(user) {
  if (!user) {
    return false;
  }

  return user.permissions.indexOf('admin') > -1;
}

export function isGranted(user, permission) {
  if (!user) {
    return false;
  }

  return user.permissions.indexOf(permission) > -1;
}

export function filterValues(obj) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}

export function logoutUser() {
  local.remove(USER_LOCALSTORAGE_KEY);
}

export function isValidationError(state) {
  return state.error
    && state.error.response
    && state.error.response.status === 400;
}

export function getValidationErrors(state) {
  return isValidationError(state) ? state.error.response.data.errors : {};
}

export function loadUser() {
  let {
    jwt,
    user
  } = local.get(USER_LOCALSTORAGE_KEY, {});

  if (false === (typeof user === 'object')) {
    local.set(USER_LOCALSTORAGE_KEY, {});
    user = null;
    jwt = null;
  }

  return {
    jwt,
    user
  };
}
