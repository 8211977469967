import ProductImageList from './ProductImageList';
import { doFetchProductImages, doRemoveProductImage } from '../../redux/actions/product_image';
import { bindConnect } from '../../utils';

export default bindConnect(state => ({
  loading:
  Boolean(state.product_image_list.pending)
  || null === state.product_image_list.data
  || Boolean(state.product_image_create.pending),
  images: state.product_image_list.data || []
}), {
  remove: doRemoveProductImage,
  fetch: doFetchProductImages
})(ProductImageList);
