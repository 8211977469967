import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import RoleSelect from '../UserCreateForm/RoleSelect';
import { isGranted } from '../../utils';
import UserBalance from '../../component/UserBalance';
import GenderSelect from 'component/GenderSelect';
import UserReferals from '../../component/UserReferals';

export default class UserDetail extends Form {

  render() {
    const {
      userl,
      errors,
      loading,
      siteId
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <div>Email: {form.email}</div>
          <div>Телефон: +{form.phone_country}{form.phone}</div>

          <Part
            type="password"
            id="password"
            name="password"
            disabled={loading}
            label='Пароль'
            value={form.password || ''}
            errors={errors}
            onChange={this.change('password')} />

          <Part
            type="password"
            id="password_confirm"
            name="password_confirm"
            disabled={loading}
            label='Пароль повторно'
            value={form.password_confirm || ''}
            errors={errors}
            onChange={this.change('password_confirm')} />

          <Part
            type="text"
            id="fam"
            name="fam"
            disabled={loading}
            label='Фамилия'
            value={form.fam || ''}
            errors={errors}
            onChange={this.change('fam')} />

          <Part
            type="text"
            id="name"
            name="name"
            disabled={loading}
            label='Имя'
            value={form.name || ''}
            errors={errors}
            onChange={this.change('name')} />

          <Part
            type="text"
            id="otch"
            name="otch"
            disabled={loading}
            label='Отчество'
            value={form.otch || ''}
            errors={errors}
            onChange={this.change('otch')} />
          
          <Part
            type="text"
            id="date_birth"
            name="date_birth"
            disabled={loading}
            label='Дата рождения'
            value={form.date_birth || ''}
            errors={errors}
            onChange={this.change('date_birth')} />

          <Part
            type="text"
            id="gender"
            name="gender"
            component={GenderSelect}
            disabled={loading}
            label='Пол'
            value={form.gender || 0}
            errors={errors}
            onChange={this.change('gender')} />

          <Part
            type="text"
            id="countryname"
            name="countryname"
            disabled={loading}
            label='Страна'
            value={form.countryname || ''}
            errors={errors}
            onChange={this.change('countryname')} />

          <Part
            type="text"
            id="cityname"
            name="cityname"
            disabled={loading}
            label='Город'
            value={form.cityname || ''}
            errors={errors}
            onChange={this.change('cityname')} />

          { form.is_partner && (<div>Логин партнёра: {form.emailPartner}</div>) }

          { form.is_partner && (<Part
              component={RoleSelect}
              id="partner_role"
              name="partner_role"
              disabled={loading}
              label='Роль'
              value={form.partner_role || ''}
              errors={errors}
              onChange={this.change('partner_role')} />)}
 
          {isGranted(userl, 'admin') && form.is_partner && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')}
              siteId={siteId}
            />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
        <UserBalance siteId={siteId} id={form.id} />
        <UserReferals siteId={siteId} id={form.id} />
        
      </SpinnerOverlay>
    );
  }
}
