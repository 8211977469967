import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class ChangePasswordForm extends Form {
  render() {
    const {
      errors,
      loading
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="password"
            id="password_current"
            name="password_current"
            disabled={loading}
            label='Ваш текущий пароль'
            value={this.state.form.password_current || ''}
            errors={errors}
            onChange={this.change('password_current')} />

          <Part type="password"
                id="password"
                name="password"
                disabled={loading}
                label='Новый пароль'
                value={this.state.form.password || ''}
                errors={errors}
                onChange={this.change('password')} />

          <Part type="password"
                name="password_confirm"
                disabled={loading}
                label='Подтверждение пароля'
                value={this.state.form.password_confirm || ''}
                errors={errors}
                onChange={this.change('password_confirm')} />
          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Изменить пароль
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
