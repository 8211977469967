import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../../firefly/component';

export default class SettingsForm extends Form {

  prepare(form) {

    const {
      siteId
    } = this.props;

    const formData = new FormData();

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
    });
    formData.set('site_id', siteId);  

    return formData;
  }

  render() {
    const {
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            required={false}
            type="textarea"
            id="about"
            name="about"
            disabled={loading}
            label='О проекте'
            value={form.about || ''}
            errors={errors}
            onChange={this.change('about')} />

          <Part
            required={false}
            type="textarea"
            id="service_rules"
            name="service_rules"
            disabled={loading}
            label='Условия сервиса'
            value={form.service_rules || ''}
            errors={errors}
            onChange={this.change('service_rules')} />

          <Part
            required={false}
            type="textarea"
            id="privacy_and_policy"
            name="privacy_and_policy"
            disabled={loading}
            label='Соглашение о конфциденциальности'
            value={form.privacy_and_policy || ''}
            errors={errors}
            onChange={this.change('privacy_and_policy')} />

          <Part
            required={false}
            type="textarea"
            id="kak_nakopit"
            name="kak_nakopit"
            disabled={loading}
            label='Как накопить и потратить'
            value={form.kak_nakopit || ''}
            errors={errors}
            onChange={this.change('kak_nakopit')} />            

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
