import React from 'react';
import { Form } from '../../firefly/component';

export default class GenderSelect extends Form {
  render() {
    const {
      name,
      value,
      onChange
    } = this.props;

    const nodes = [
      {id: 1, name: 'Муж'},
      {id: 2, name: 'Жен'},
    ].map((role, i) => (
      <option key={i} value={role.id}>{role.name}</option>
    ));

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        {nodes}
      </select>
    );
  }
}
