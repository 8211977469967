import * as types from '../actionTypes';

export function doFetchCodeList(params) {
  return {
    type: types.CODE_LIST,
    request: { url: '/code/list', params }
  };
}

export function doCodeGenerate(data) {
  return {
    type: types.CODE_GENERATE,
    request: { url: '/code/generate', method: 'post', data }
  };
}

export function doCodeDownload({ partner_id }) {
  return {
    type: types.CODE_DOWNLOAD,
    request: { url: `/code/download/${partner_id}` }
  };
}
