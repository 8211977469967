import { bindConnect, getValidationErrors } from '../../utils';
import PartnerForm from '../PartnerForm/PartnerForm';
import { doPartnerCreate } from '../../redux/actions/partner';

export default bindConnect(state => ({
  loading: Boolean(state.partner_create.pending),
  errors: getValidationErrors(state.partner_create)
}), {
  callback: doPartnerCreate
})(PartnerForm);
