import { bindConnect, getValidationErrors } from '../../utils';
import CodeDownloadForm from './CodeDownloadForm';
import { doCodeDownload } from '../../redux/actions/code';

export default bindConnect(state => ({
  errors: getValidationErrors(state.code_download),
  loading: Boolean(state.code_download.pending),
  codes: state.code_download.data || []
}), {
  callback: doCodeDownload
})(CodeDownloadForm);
