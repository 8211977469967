import { bindConnect } from '../../utils';
import NewsTable from './NewsTable';
import * as newsActions from '../../redux/actions/news';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect((state, props) => ({
  loading: Boolean(state.news_list.pending)
  || !state.news_list.data
  || Boolean(state.news_remove.pending),
  ...(state.news_list.data || {}),
  siteId: props.siteId
}), {
  notification: notificationActions.add,
  fetch: newsActions.list,
  remove: newsActions.remove
})(NewsTable);
