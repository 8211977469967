import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import StatPartnerFilter from './StatPartnerFilter';
import * as statActions from '../../redux/actions/stat.js';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    partner_id: state.current_user.user.id
  } : {
    ...props.form
  },
  errors: getValidationErrors(state.stat_list),
  loading: Boolean(state.stat_list.pending)
}), {
  callback: statActions.list
})(StatPartnerFilter);
