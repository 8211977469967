import { bindConnect } from '../../utils';
import CityFilter from './CityFilter';
import * as cityActions from '../../redux/actions/city';

export default bindConnect(state => ({
  objects: state.city_options.data || [],
  loading: Boolean(state.city_options.pending) || null === state.city_options.data
}), {
  fetch: cityActions.options
})(CityFilter);
