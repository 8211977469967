import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import DraftScanStatFilter from '../../component/DraftScanStatFilter';
import DraftScanStatList from '../../component/DraftScanStatList';

export default class DraftScanStat extends Component {

  state = {
    success: false,
    data: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      success: true,
      data: response.data
    });
  };

  dateFrom() {
    let d = new Date();
    return d.toISOString();
  }

  defForm() {
    return {
      start_at: this.dateFrom(), 
      end_at: this.dateFrom()
    };
  }

  render() {

    const {
      data,
      success
    } = this.state;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/draftscan', text: 'Чеки' },
      { text: 'Статистика по чекам' }
    ];

    console.dir(data);
    let res;
    if (success && data !== undefined && data.objects !== undefined) {
      res = (<DraftScanStatList
        objects={data.objects}
        success={success}
      />);
    }

    return (      
      <Page title='Статистика по чекам' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Статистика по чекам</H1>
          <DraftScanStatFilter form={this.defForm()} onSuccess={this.onSuccess}/>
        </Transition>
        <br/>
        <Transition>          
          {success ? res : (<div>&nbsp;</div>)}          
        </Transition>
      </Page>
    );
  }
}
