import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import MonetaEditForm from '../../component/MonetaEditForm';
import { Spinner } from '../../firefly/component';
import { isGranted } from '../../utils';

export default class EditMoneta extends Component {

  static propTypes = {
    moneta: PropTypes.object,
    loading: PropTypes.bool,
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired
  };
    
  onSuccess = ({ response }) => {

    const {
      notification,
      match
    } = this.props;

    notification({
      title: 'Выполнено',
      text: 'Монета сохранена'
    });    
    this.fetch(match.params.id);
  };

  fetch = (id = 0) => {
    const {
      fetch,
    } = this.props;

    fetch(id);
  };

  componentDidMount() {
    const {
      match
    } = this.props;    
    this.fetch(match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId,
      match
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      if (match.params.id !== this.props.match.params.id) {
        fetch(match.params.id);
      }
    }
  }

  render() {

    const {
      loading,
      moneta,
      match,
      siteId,
      user
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/monets', text: 'Монеты' },
      { text: 'Настройка монеты' }
    ];

    return (
      <Page title='Настройка монеты' breadcrumbs={breadcrumbs}>
        {isGranted(user, 'admin') ? (<Transition>
          <H1>Настройка монеты</H1>
          {loading ? <Spinner/> : <MonetaEditForm id={match.params.id} siteId={siteId} form={moneta} onSuccess={this.onSuccess} />}
        </Transition>) : <div></div>}
      </Page>
    );
  }
}
