import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import UserSelect from '../UserSelect';
import CitySelect from '../CitySelect';
import PartnerSelect from '../PartnerSelect';

export default class PartnerForm extends Form {
  componentDidMount() {
    const { form } = this.props;
    delete form.user;
    delete form.products;
    delete form.logo_url;
    delete form.logo;
    delete form.created_at;
    delete form.updated_at;
    delete form.referal_level;
    delete form.balance_emr;

    this.setState({ form });
  }

  prepare(form) {
    const formData = new FormData();
    const {
      siteId
    } = this.props;

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
      formData.set('site_id', siteId);
    });

    return formData;
  }

  render() {
    const {
      errors,
      loading,
      siteId
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="text"
            id="name"
            name="name"
            disabled={loading}
            label='Название'
            value={form.name || ''}
            errors={errors}
            onChange={this.change('name')} />

          <Part
            type="text"
            id="inn"
            name="inn"
            disabled={loading}
            label='ИНН'
            value={form.inn || ''}
            errors={errors}
            onChange={this.change('inn')} />

          <Part
            type="file"
            id="logo"
            name="logo"
            required={false}
            disabled={loading}
            label='Логотип'
            errors={errors}
            onChange={this.change('logo')} />

          <Part
            required={false}
            component={UserSelect}
            id="user_id"
            name="user_id"
            disabled={loading}
            label='Руководитель'
            value={form.user_id || ''}
            errors={errors}
            onChange={this.change('user_id')}
            siteId={siteId}
          />

          <Part
            required={false}
            type="textarea"
            id="description"
            name="description"
            disabled={loading}
            label='Описание'
            value={form.description || ''}
            errors={errors}
            onChange={this.change('description')} />

          <Part
            required={false}
            type="tel"
            id="phone"
            name="phone"
            disabled={loading}
            label='Телефон'
            value={form.phone || ''}
            errors={errors}
            onChange={this.change('phone')} />

          <Part
            required={false}
            type="text"
            id="email"
            name="email"
            disabled={loading}
            label='E-mail'
            value={form.email || ''}
            errors={errors}
            onChange={this.change('email')} />

          <Part
            required={false}
            type="text"
            id="address"
            name="address"
            disabled={loading}
            label='Адрес организации'
            value={form.address || ''}
            errors={errors}
            onChange={this.change('address')} />
          
          <Part
            required={false}
            type="text"
            id="categ"
            name="categ"
            disabled={loading}
            label='Категория бизнеса'
            value={form.categ || ''}
            errors={errors}
            onChange={this.change('categ')} />

          <Part
            required={false}
            type="text"
            id="site"
            name="site"
            disabled={loading}
            label='Сайт'
            value={form.site || ''}
            errors={errors}
            onChange={this.change('site')} />

          <Part
            required={false}
            type="text"
            id="vk"
            name="vk"
            disabled={loading}
            label='VK'
            value={form.vk || ''}
            errors={errors}
            onChange={this.change('vk')} />
          
          <Part
            required={false}
            type="text"
            id="fb"
            name="fb"
            disabled={loading}
            label='FB'
            value={form.fb || ''}
            errors={errors}
            onChange={this.change('fb')} />
          
          <Part
            required={false}
            type="text"
            id="inst"
            name="inst"
            disabled={loading}
            label='Instagram'
            value={form.inst || ''}
            errors={errors}
            onChange={this.change('inst')} />
            
          <Part
            required={false}
            type="text"
            id="ok"
            name="ok"
            disabled={loading}
            label='OK'
            value={form.ok || ''}
            errors={errors}
            onChange={this.change('ok')} />        
          
          <Part
            required={false}
            type="text"
            id="twit"
            name="twit"
            disabled={loading}
            label='Telegram'
            value={form.twit || ''}
            errors={errors}
            onChange={this.change('twit')} />

          <Part
            required={false}
            type="text"
            id="wats"
            name="wats"
            disabled={loading}
            label='Whatsup'
            value={form.wats || ''}
            errors={errors}
            onChange={this.change('wats')} />            

          <Part
            required={false}
            component={CitySelect}
            id="city_id"
            name="city_id"
            disabled={loading}
            label='Город'
            value={form.city_id || ''}
            errors={errors}
            onChange={this.change('city_id', 'reactselect')} />

          <Part
            required={false}
            component={PartnerSelect}
            id="referal_partner_id"
            name="referal_partner_id"
            disabled={loading}
            label='Реферал'
            value={form.referal_partner_id || ''}
            errors={errors}
            onChange={this.change('referal_partner_id')}
            siteId={siteId}
          />

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
