import PromocodeList from './PromocodeList';

import { bindConnect } from '../../utils';
import * as promocodeActions from '../../redux/actions/promocode';

export default bindConnect((state) => ({
    loading: Boolean(state.promocode_export.pending) || !state.promocode_export.data,
}), {
  promoexport: promocodeActions.doPromocodeExport,
})(PromocodeList);
