import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class MonetaSelect extends Form {
  componentDidMount() {
    const {
      partner_id,
      siteId,
      fetch
    } = this.props;    
    fetch({partner_id: partner_id, site_id: siteId});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      partner_id,
      siteId,
      fetch
    } = this.props;

    if (prevProps.siteId !== siteId || prevProps.partner_id !== partner_id) {
      fetch({partner_id: partner_id, site_id: siteId})
    }
  }
  
  render() {
    const {
      monets,
      //name,
      value,
      onChange,
      loading
    } = this.props;

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={monets}
        value={value}
        placeholder="Выберите монету"
        optionsAll={-1}
      /> : <select className="b-input b-input--select"></select>)
    );
  }
}
