import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export default class Transition extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  };

  timer = undefined;

  state = {
    cls: ''
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        cls: 'fade-enter-active'
      });
    }, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      children,
      ...rest
    } = this.props;

    return (
      <div
        {...rest}
        className={cx('fade-enter', this.state.cls)}>
        {children}
      </div>
    );
  }
}
