import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.CONTEST_LIST,
    request: { url: '/contest/list', params }
  };
}

export function create(data) {
  return {
    type: types.CONTEST_CREATE,
    request: { url: '/contest/create', method: 'post', data }
  };
}

export function makeWinner(id) {
  return {
    type: types.CONTEST_CREATE,
    request: {
      url: `/contest/random_winner/${id}`,
      method: 'post'
    }
  };
}

export function update(data) {
  const id = data instanceof FormData ? data.get('id') : data.id;

  return {
    type: types.CONTEST_UPDATE,
    request: { url: `/contest/update/${id}`, method: 'post', data }
  };
}

export function view(id) {
  return {
    type: types.CONTEST_VIEW,
    request: { url: `/contest/view/${id}` }
  };
}

export function remove(id) {
  return {
    type: types.CONTEST_REMOVE,
    request: { url: `/contest/remove/${id}`, method: 'delete' }
  };
}
