import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import DraftscanEditForm from './DraftscanEditForm';
import * as draftscanActions from '../../redux/actions/draftscan';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    partner_id: state.current_user.user.id
  } : {
    ...props.form
  },
  id: props.id,
  errors: getValidationErrors(state.draftscan_update),
  loading: Boolean(state.draftscan_update.pending)
}), {
  callback: draftscanActions.doDraftscanUpdate
})(DraftscanEditForm); 
