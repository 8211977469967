import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import MonetaEditForm from '../../component/MonetaEditForm';
import { isGranted } from '../../utils';

export default class MonetaAdd extends Component {
  static defaultProps = {
    objects: null,
    filter: []
  };

  static propTypes = {
    notification: PropTypes.func.isRequired,
  };

  state = {
    success: false,
    moneta: null
  };

  onSuccess = ({ response }) => {

    const {
      notification
    } = this.props;

    notification({
      title: 'Выполнено',
      text: 'Монета сохранена'
    });    
    
    this.setState({
      success: true
    });
  };

  fetch = (id = 0) => {
    const {
      fetch,
      filter
    } = this.props;

    fetch({
      ...filter,
      id
    });
  };  

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;
    console.log(objects);

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/monets', text: 'Монеты' },
      { text: 'Создание монеты' }
    ];

    const {
      success,
      moneta
    } = this.state;

    const {
      objects,
      siteId,
      user
    } = this.props;


    if (success) {
      return <Redirect to='/monets' />;
    }

    return (
      <Page title='Создание монеты' breadcrumbs={breadcrumbs}>
        {isGranted(user, 'admin') ? (<Transition>
          <H1>Создание монеты</H1>
          <h5>Баланс: {objects === null ? '...' : objects['balance']}</h5>
          <MonetaEditForm form={moneta} onSuccess={this.onSuccess} siteId={siteId} />
        </Transition>) : <div></div>}
      </Page>
    );
  }
}
