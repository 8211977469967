import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import ContestUpdateForm from '../ContestForm';
import * as contestActions from '../../redux/actions/contest';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    partner_id: state.current_user.user.id
  } : {
    ...props.form
  },
  errors: getValidationErrors(state.contest_update),
  loading: Boolean(state.contest_update.pending)
}), {
  callback: contestActions.update
})(ContestUpdateForm);
