import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class RestoreConfirmForm extends Form {
  state = {
    valid: false,
    form: {},
    errors: {}
  };

  resend = e => {
    e.preventDefault();

    const {
      resend,
      notification
    } = this.props;

    resend({ login: this.getEmail() }).then(() => {
      notification({
        title: 'Письмо успешно отправлено'
      });
    });
  };
  submit = e => {
    e.preventDefault();

    const {
      valid,
      form
    } = this.state;
    form.login = this.getEmail();
    console.log(form);

    const {
      onSuccess,
      confirm,
      check
    } = this.props;

    if (valid) {
      confirm(form).then(() => {
        onSuccess();
      });
    } else {
      check(form).then(() => {
        this.setState({ valid: true });
      });
    }
  };

  getEmail() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('email');
  }

  render() {
    const {
      loading,
      errors
    } = this.props;

    const {
      valid,
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="text"
                name="token"
                id="token"
                disabled={loading}
                autoComplete="new-password"
                errors={errors}
                label='Код'
                value={this.state.form.token || ''}
                style={{ display: valid ? 'none' : 'block' }}
                onChange={this.change('token')} />
          {valid && <div>
            <Part type="password"
                  name="password"
                  id="password"
                  disabled={loading}
                  autoComplete="new-password"
                  errors={errors}
                  label='Новый пароль'
                  value={this.state.form.password || ''}
                  onChange={this.change('password')} />
            <Part type="password"
                  name="password_confirm"
                  id="password_confirm"
                  disabled={loading}
                  autoComplete="new-password"
                  errors={errors}
                  label='Подтверждение пароля'
                  value={this.state.form.password_confirm || ''}
                  onChange={this.change('password_confirm')} />
                  <br/>
          </div>}
          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Сохранить
            </button>
            &nbsp;
            {this.getEmail() && (
              <button type="button"
                      disabled={loading}
                      onClick={this.resend}
                      className="b-button b-button_secondary b-login__button">
                Отправить письмо повторно
              </button>
            )}
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
