import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PromocodeTable from '../../component/PromocodeTable';

export default class PromocodeList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      filedownloadlink: null
    };
  }
    
  exportCodes = () => {   
    const {
      promoexport
    } = this.props;

    promoexport().then((res) => {
      /*let myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
      let csv = res.data.objects;
      let blob = new Blob([csv], { type: 'text/csv' });  
      let csvUrl = myURL.createObjectURL(blob);
      this.setState({
        filedownloadlink: csvUrl
      });*/
      let data = new Blob([res.data.objects], {type: 'text/csv'});
      let csvURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'code_list.csv');
      tempLink.click();
    });
  }

  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Промокоды' }
    ];

    const {
      filedownloadlink
    } = this.state;

    const {
      siteId
    } = this.props;
    
    return (
      <Page title='Промокоды' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Промокоды</H1>
          <div className="toolbar">
            <Link to="/promocode/generate" className="b-button">
              Генерировать коды
            </Link>
            &nbsp;
            <Link onClick={this.exportCodes} to='#' className="b-button">
              Экспорт кодов
            </Link>
          </div>
          {filedownloadlink &&<div><a download="CodesExport.csv" href={filedownloadlink}>Скачать файл экспорта</a></div>}
          <PromocodeTable siteId={siteId} />
        </Transition>
      </Page>
    );
  }
}
