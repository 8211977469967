import { bindConnect, getValidationErrors } from '../../utils';
import PromocodeGenerateForm from './PromocodeGenerateForm';
import { doPromocodeGenerate } from '../../redux/actions/promocode';

export default bindConnect(state => ({
  user: state.current_user.user,
  errors: getValidationErrors(state.promocode_generate),
  loading: Boolean(state.promocode_generate.pending)
}), {
  callback: doPromocodeGenerate
})(PromocodeGenerateForm);
