import {
  createRequestInstance,
  watchRequests,
  success
} from 'redux-saga-requests';
import {
  all,
  takeLatest
} from 'redux-saga/effects';
import { createDriver } from 'redux-saga-requests-axios';
import axios from '../../api';
import * as types from '../actionTypes';
import {
  doPersistUser
} from './user';
import {
  onRequest,
  onError
} from './requests';
import { doUserLogout } from './auth';

export function* rootSaga() {
  yield createRequestInstance({
    driver: createDriver(axios),
    onRequest,
    onError
  });

  yield all([
    watchRequests(),
    takeLatest(
      [
        success(types.USER_LOGIN),
        success(types.USER_REGISTRATION_CONFIRM),
        success(types.USER_RESTORE_CONFIRM)
      ],
      doPersistUser
    ),
    takeLatest(types.USER_LOGOUT, doUserLogout)
  ]);
}
