import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import StatPartnerFilter from '../../component/StatPartnerFilter';
import StatPartnerList from '../../component/StatPartnerList';
import StatPartnerMap from '../../component/StatPartnerMap';

export default class StatPartner extends Component {

  state = {
    success: false,
    data: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      success: true,
      data: response.data
    });
  };

  dateFrom() {
    let d = new Date();
    return d.toISOString();
  }

  defForm() {
    return {
      start_at: this.dateFrom(), 
      end_at: this.dateFrom()
    };
  }

  render() {

    const {
      data,
      success
    } = this.state;

    const {
      siteId
    } = this.props;    

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Статистика' }
    ];

    let res;
    if (success && data !== undefined && data.type !== undefined && data.objects !== undefined) {
      if (data.type > 0) {
        res = (<StatPartnerMap
          type={data.type}
          objects={data.objects}
          success={success}
        />);
      } else {
        res = (<StatPartnerList
          objects={data.objects}
          success={success}
        />);
      }
    }

    return (      
      <Page title='Статистика' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Статистика</H1>
          <StatPartnerFilter form={this.defForm()} onSuccess={this.onSuccess} siteId={siteId}/>
        </Transition>
        <br/>
        <Transition>          
          {success ? res : (<div>&nbsp;</div>)}
        </Transition>
      </Page>
    );
  }
}
