import React, { Component } from 'react';
import Transition from '../../component/Transition';
import UserCreateForm from '../../component/UserCreateForm';
import Page from '../../component/Page';
import { Redirect } from 'react-router-dom';

export default class UserCreate extends Component {
  state = {
    success: false
  };

  onSuccess = ({ response }) => {
    this.props.notification({ title: 'Выполнено' });

    this.setState({
      id: response.data.id,
      success: true
    });
  };

  render() {

    const { success } = this.state;

    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to={`/user`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/user', text: 'Пользователи' },
      { text: 'Добавление пользователя' }
    ];

    return (
      <Page title='Добавление пользователя' breadcrumbs={breadcrumbs}>
        {(
          <Transition>
            <UserCreateForm onSuccess={this.onSuccess} siteId={siteId} />
          </Transition>
        )}
      </Page>
    );
  }
}
