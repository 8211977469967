import { bindConnect } from '../../utils';
import UserView from './UserView';
import * as userActions from '../../redux/actions/user';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.user_view.pending) || !state.user_view.data,
  user: state.user_view.data,
  error: state.user_view.error
}), {
  fetch: userActions.view,
  notification: notificationActions.add
})(UserView);
