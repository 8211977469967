import React from 'react';
import { CSVLink } from 'react-csv';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';

export default class CodeDownloadForm extends Form {
  render() {
    const {
      codes,
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part component={PartnerSelect}
                id="partner_id"
                name="partner_id"
                disabled={loading}
                label='Партнер'
                value={form.partner_id || ''}
                errors={errors}
                onChange={this.change('partner_id')} />

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Подготовить файл
            </button>
          </FormRow>
        </form>
        {codes.length > 0 && (
          <div>
            <div className="divider" />
            <CSVLink filename="exported_codes.csv"
                     className="b-button"
                     data={codes}>
              Скачать
            </CSVLink>
          </div>
        )}
      </SpinnerOverlay>
    );
  }
}
