import React from 'react';
import { Form } from '../../firefly/component';

export default class BalanceTypeSelect extends Form {
  render() {
    const {
      name,
      value,
      onChange
    } = this.props;

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        <option value="0">Эквайринг</option>
        <option value="1">Выставить счет</option>
      </select>
    );
  }
}
