import { bindConnect } from '../../utils';
import ContestTable from './ContestTable';
import * as contestActions from '../../redux/actions/contest';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect((state,props) => ({
  loading: Boolean(state.contest_list.pending)  
  || !state.contest_list.data
  || Boolean(state.contest_remove.pending),
  siteId: props.siteId,
  ...(state.contest_list.data || {})
}), {
  fetch: contestActions.list,
  remove: contestActions.remove,
  notification: notificationActions.add
})(ContestTable);
