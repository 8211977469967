import qs from 'qs';
import { withRouter } from 'react-router-dom';
import ContestList from './ContestList';
import { bindConnect } from '../../utils';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  siteId: props.siteId,
  filter: {
    page: 1,
    page_size: 10,
    ...qs.parse(props.location.search.slice(1))
  }
}))(withRouter(ContestList));
