import { bindConnect } from '../../utils';
import MonetaImgs from './MonetaImgs';
import * as monetsActions from '../../redux/actions/monets';
import { doFetchMonetaImages } from '../../redux/actions/moneta_imgs';

export default bindConnect(state => ({
  loading: Boolean(state.monets_view.pending) || !state.monets_view.data,
  moneta: state.monets_view.data
}), {
  fetch: monetsActions.view,
  fetchImages: doFetchMonetaImages
})(MonetaImgs);
