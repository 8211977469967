import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import UserDetail from '../../component/UserDetail';
import { Spinner } from '../../firefly/component';
import NoMatch from '../NoMatch';

export default class UserView extends Component {
  static propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    error: PropTypes.any
  };

  static defaultProps = {
    error: null,
    user: null,
    loading: true
  };

  state = {
    success: false
  };

  componentDidMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId,
      fetch,
      match
    } = this.props;

    if (prevProps.siteId !== siteId && match.params.id !== prevProps.match.params.id) {
      fetch(match.params.id)
    }
  }

  onSuccess = () => {
    this.props.notification({ title: 'Выполнено' });

    this.setState({ success: true });
  };

  render() {
    const {
      loading,
      user,
      error,
      siteId
    } = this.props;

    const {
      success
    } = this.state;

    if (error) {
      return <NoMatch />;
    }

    if (success) {
      return <Redirect to={`/user`} />;
    }    

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/user', text: 'Пользователи' },
      { text: 'Детальный просмотр пользователя' }
    ];

    return (
      <Page title='Детальный просмотр пользователя' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <UserDetail form={user} onSuccess={this.onSuccess} siteId={siteId} />
          </Transition>
        )}
      </Page>
    );
  }
}
