import { bindConnect } from '../../utils';
import MonetsTable from './MonetsTable';
import * as monetsActions from '../../redux/actions/monets';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.monets_list.pending) || !state.monets_list.data,
  ...(state.monets_list.data || { meta: {}, objects: [] })
}), {
  remove: monetsActions.remove,
  fetch: monetsActions.list,
  notification: notificationActions.add
})(MonetsTable);