import { bindConnect } from '../../utils';
import CityUpdate from './CityUpdate';
import * as cityActions from '../../redux/actions/city';

export default bindConnect(state => ({
  loading: Boolean(state.city_view.pending) || !state.city_view.data,
  city: state.city_view.data
}), {
  fetch: cityActions.view
})(CityUpdate);
