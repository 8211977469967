import * as types from '../actionTypes';

export function logout() {
  return { type: types.USER_LOGOUT };
}

export function doLogin(data) {
  return {
    type: types.USER_LOGIN,
    request: { url: '/user/login', method: 'post', data }
  };
}

export function didRestoreResend(data) {
  return {
    type: types.USER_RESTORE_RESEND,
    request: { url: '/user/restore/resend', method: 'post', data }
  };
}

export function didRegistrationResend(data) {
  return {
    type: types.USER_REGISTRATION_RESEND,
    request: { url: '/user/registration/resend', method: 'post', data }
  };
}

export function didRestoreConfirm(data) {
  return {
    type: types.USER_RESTORE_CONFIRM,
    request: {
      url: '/user/restore/confirm',
      data,
      method: 'post'
    }
  };
}

export function didRestoreCheck(data) {
  return {
    type: types.USER_RESTORE_CHECK,
    request: { url: '/user/restore/check', method: 'post', data }
  };
}

export function didRegistrationConfirm(data) {
  return {
    type: types.USER_REGISTRATION_CONFIRM,
    request: { url: '/user/registration-partner-confirm', method: 'post', data }
  };
}

export function doRegistration(data) {
  return {
    type: types.USER_REGISTRATION,
    request: { url: '/user/registration-partner', method: 'post', data },
    meta: { email: data.email }
  };
}

export function didRestore(data) {
  return {
    type: types.USER_RESTORE,
    request: { url: '/user/restore', method: 'post', data }
  };
}
