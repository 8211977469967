import { bindConnect, getValidationErrors } from '../../utils';
import PartnerForm from '../PartnerForm/PartnerForm';
import { doPartnerUpdate } from '../../redux/actions/partner';

export default bindConnect(state => ({
  errors: getValidationErrors(state.partner_update),
  loading: Boolean(state.partner_update.pending)
}), {
  callback: doPartnerUpdate
})(PartnerForm);
