import { bindConnect } from '../../utils';
import UserBalances from './UserBalances';
import * as userActions from '../../redux/actions/user';

export default bindConnect((state,props) => ({
  user: state.current_user.user,
  loading: Boolean(state.user_balances.pending) || !state.user_balances.data,
  ...(state.user_balances.data || { meta: {}, objects: [] }),
  siteId: props.siteId
}), {
  fetch: userActions.balances,
})(UserBalances);
