import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import CodeExportForm from '../../component/CodeExportForm';
import PermissionContainer from '../../component/PermissionContainer';

export default class CodeExport extends Component {
  state = {
    redirect_url: null
  };

  onSuccess = ({ redirect_url }) => {
    this.setState({ redirect_url });
  };

  render() {
    const {
      redirect_url
    } = this.state;

    if (redirect_url) {
      return <Redirect to="/code" />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/code', text: 'Коды' },
      { text: 'Экспорт кодов' }
    ];

    return (
      <Page title='Экспорт кодов' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Экспорт кодов</H1>
            <CodeExportForm onSuccess={this.onSuccess} />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
