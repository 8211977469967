import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import { isGranted } from '../../utils';
import BalanceTypeSelect from '../BalanceTypeSelect';

export default class BalanceAddForm extends Form {
  render() {
    const {
      user,
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>

        <Part
            component={BalanceTypeSelect}
            id="type"
            name="type"
            disabled={loading}
            label='Тип пополнения'
            value={form.type || ''}
            errors={errors}
            onChange={this.change('type')} />

          <Part
            type="number"
            min="1" 
            max="10000"
            id="price"
            name="price"
            disabled={loading}
            label='Сумма EMR'
            value={form.price || ''}
            errors={errors}
            onChange={this.change('price')} />

          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')} />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Продолжить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
