import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class RegistrationForm extends Form {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]).isRequired,
    onSuccess: PropTypes.func.isRequired
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.search);

    this.setState({
      form: { 'promo': query.get('code') }
    });
  }

  render() {
    const {
      errors,
      loading
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="email"
                name="emailPartner"
                id="emailPartner"
                autoFocus={true}
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Электронная почта'
                value={this.state.form.emailPartner || ''}
                onChange={this.change('emailPartner')} />
          
          <Part type="text"
                name="phonePartner"
                id="phonePartner"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Телефон'
                value={this.state.form.phonePartner || ''}
                onChange={this.change('phonePartner')} />

          <Part type="password"
                id="password"
                name="password"
                autoComplete="new-password"
                errors={errors}
                label='Пароль'
                value={this.state.form.password || ''}
                onChange={this.change('password')} />

          <Part type="password"
                name="password_confirm"
                autoComplete="new-password"
                errors={errors}
                label='Подтверждение пароля'
                value={this.state.form.password_confirm || ''}
                onChange={this.change('password_confirm')} />

          <Part type="text"
                required={false}
                name="promo"
                id="promo"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Реферальный код'
                value={this.state.form.promo || ''}
                onChange={this.change('promo')} />

          <Part type="text"
                name="orgname"
                id="orgname"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Название компании'
                value={this.state.form.orgname || ''}
                onChange={this.change('orgname')} />
          
          <Part type="text"
                name="inn"
                id="inn"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='ИНН'
                value={this.state.form.inn || ''}
                onChange={this.change('inn')} />

          <Part type="text"
                name="fio"
                id="fio"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='ФИО'
                value={this.state.form.fio || ''}
                onChange={this.change('fio')} />                
          
          <Part type="text"
                name="categ"
                id="categ"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Категория бизнеса'
                value={this.state.form.categ || ''}
                onChange={this.change('categ')} />                
          
          <Part type="text"
                required={false}
                name="site"
                id="site"
                autoCapitalize="off"
                autoCorrect="off"
                errors={errors}
                label='Сайт'
                value={this.state.form.site || ''}
                onChange={this.change('site')} />                
                                
          <FormRow className="b-form__pane">
            <button type="submit" className="b-button">
              Регистрация
            </button>
            <Link className="menu__link" to="/login">
              Отмена
            </Link>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
